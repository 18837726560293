import DateTimeCell from "../../../../../../components/Table/cells/DateTime"
import NumberCell from "../../../../../../components/Table/cells/Number"
import { toRupiah } from "../../../../../../helpers/common"
import MessageCell from "./MessageCell"
import StatusCell from "./StatusCell"

const withdrawColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        className: 'ps-2',
        Cell: ({ ...props }) => <NumberCell {...props} />
    }, {
        Header: 'Full Name',
        accessor: 'full_name',
    }, {
        Header: 'Ewallet',
        accessor: 'ewallet_desc',
    }, {
        Header: 'Ewallet Address',
        accessor: 'ewallet_address',
    }, {
        Header: 'Value',
        accessor: 'value',
        Cell: ({ ...props }) => toRupiah(props.data[props.row.index].value)
    }, {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ ...props }) => <StatusCell status={props.data[props.row.index].withdraw_status} />
    }, {
        Header: 'Message',
        accessor: 'message',
        Cell: ({ ...props }) => <MessageCell status={props.data[props.row.index].withdraw_status} message={props.data[props.row.index].message} />
    }, {
        Header: 'Last Update',
        accessor: 'updated_at',
        Cell: ({ ...props }) => <DateTimeCell dateTime={props.data[props.row.index].updated_at} />
    },
]

const columnAliases = {
    number: 'id'
}

export { withdrawColumns, columnAliases }