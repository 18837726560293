import { useFormik } from "formik"
import * as Yup from "yup"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import AsyncSelect from "react-select/async"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

import { getLessons } from "../../../../../../models/courses/courses"
import { getDateRange } from "../../../../../../helpers/common"
import { useEffect, useState } from "react"
import Spinner from "../../../../../../components/Spinner/Spinner"


const schema = Yup.object().shape({
    name: Yup.string().required().min(5).max(255).label('Name'),
    schedule_start: Yup.date().required().nullable().label('Start Date'),
    schedule_end: Yup.date().required().nullable().label('End Date')
})

const DuplicateScheduleForm = ({ queryClient, courseId, schedule, onSubmit, submitLoading }) => {
    const [dateRange, setDateRange] = useState([schedule?.schedule_start ? moment(schedule.schedule_start).toDate() : null, schedule?.schedule_end ? moment(schedule.schedule_end).toDate() : null])
    const formik = useFormik({
        initialValues: {
            name: schedule?.name,
            schedule_start: schedule?.schedule_start ? moment(schedule.schedule_start).toDate() : '',
            schedule_end: schedule?.schedule_end ? moment(schedule.schedule_end).toDate() : '',
            sessions: schedule?.sessions ? [...schedule.sessions.map(session => ({
                start_date: moment.utc(session.start_datetime).local().toDate(),
                start_time: moment.utc(session.start_datetime).local().toDate(),
                lesson: {
                    value: session.lesson_id,
                    label: session.lesson.title
                },
                description: session.description || ''
            }))] : []
        },
        validationSchema: schema,
        onSubmit: (values) => {
            const data = {
                name: values.name,
                schedule_start: moment(values.schedule_start).format('YYYY-MM-DD'),
                schedule_end: moment(values.schedule_end).format('YYYY-MM-DD'),
                sessions: []
            }

            values.sessions.map(session => {
                if (!session.lesson) return false

                return data.sessions.push({
                    lesson_id: session.lesson.value,
                    description: session.description,
                    start_datetime: moment(moment(session.start_date).format('YYYY-MM-DD') + ' ' + moment(session.start_time).format('HH:mm')).utc().format('YYYY-MM-DD HH:mm:ss')
                })
            })

            onSubmit(data)
        }
    })

    useEffect(() => {
        const dates = getDateRange(formik.values.schedule_start, formik.values.schedule_end)
        let sessions = formik.values.sessions
        dates.map((date, i) => {
            if (sessions.length > i) return sessions[i].start_date = moment(date).toDate()

            return sessions.push({
                start_date: moment(date).toDate(),
                start_time: new Date(),
                lesson: null,
                description: ''
            })
        })

        if (dates.length < sessions.length) {
            const tempSessions = sessions.slice(0, dates.length - sessions.length)
            sessions = tempSessions
        }

        formik.setFieldValue('sessions', sessions)
    }, [formik.values.schedule_start, formik.values.schedule_end])

    useEffect(() => {
        if (!dateRange[0] || !dateRange[1]) return () => true
        
        formik.setFieldValue('schedule_end', dateRange[1])
        formik.setFieldValue('schedule_start', dateRange[0])
    }, [dateRange])

    const searchLesson = async search => queryClient.fetchQuery(`course-${courseId}-lessons`, () => getLessons({ courseId, title: search, types: 'webinar,offline_activity' })).then(res => res.data.map(lesson => {
        return {
            label: lesson.title,
            value: lesson.id
        }
    }))

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-4">
                <label className="form-label required">Schedule Name</label>
                <input type="text" name="name" className="form-control" placeholder="Schedule Name" onChange={formik.handleChange} value={formik.values.name} />
                {formik.errors.name ? <small className="form-text text-danger">{formik.errors.name}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label required">Date Range</label>
                <ReactDatePicker
                    className="form-control"
                    selectsRange
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    onChange={(date) => setDateRange(date)}
                />
            </div>
            {formik.values.sessions.map((session, i) => (
                <>
                    <div className="separator separator-dashed my-10"></div>
                    <div className="row mt-2">
                        <div className="col-6 col-lg-3 form-group">
                            <label className="form-label">Date</label>
                            <p className="mt-3 mb-0 fs-5 fw-semibold">{moment(session.start_date).format('dddd, DD MMMM YYYY')}</p>
                        </div>
                        <div className="col-6 col-lg-2 form-group">
                            <label className="form-label required">Time</label>
                            <ReactDatePicker
                                name={`sessions[${i}].start_time`}
                                className="form-control"
                                placeholderText="Start Date"
                                onChange={date => formik.setFieldValue(`sessions[${i}].start_time`, date)}
                                selected={session.start_time}
                                showTimeSelect
                                showTimeSelectOnly
                                timeFormat="HH:mm"
                                dateFormat="HH:mm"
                                autoComplete="off"
                            />
                        </div>
                        <div className="col-12 col-lg-7 form-group">
                            <label className="form-label required">Lesson</label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                loadOptions={searchLesson}
                                name={`sessions[${i}].lesson`}
                                className="form-control-sm p-0"
                                onChange={option => formik.setFieldValue(`sessions[${i}].lesson`, option)}
                                value={session.lesson}
                                isClearable
                            />
                            {formik.errors.lesson ? <small className="form-text text-danger">{formik.errors.lesson}</small> : null}
                        </div>
                        <div className="col-12 form-group mt-3">
                            <label className="form-label">Description</label>
                            <ReactQuill theme='snow' name="description" value={formik.values.description} onChange={value => formik.setFieldValue('description', value)} />
                            {formik.errors.description ? <small className="form-text text-danger">{formik.errors.description}</small> : null}
                        </div>
                    </div>
                </>
            ))}
            <button type="submit" className="btn btn-primary w-100 mt-8" disabled={submitLoading}>Create Schedule {submitLoading && <Spinner size="sm" className="ms-1" />}</button>
        </form>
    )
}

export default DuplicateScheduleForm