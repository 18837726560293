import { useOutletContext } from "react-router-dom"
import Authorization from "../../../../routing/Authorization"
import { useQuery } from "react-query"
import { getCampaignStatistic } from "../../../../models/tunjangan-hari-karya"
import SummaryCard from "../../../dashboard/components/SummaryCard"
import { toRupiah } from "../../../../helpers/common"
import { courseThumbnail } from "../../../../helpers/pictures"


const Overview = () => {
    const { campaign } = useOutletContext()
    const queryKey = `tunjangan-hari-karya-${campaign.id}-statistic`

    const { isLoading, data, isError, error } = useQuery([queryKey], () => getCampaignStatistic(campaign.id))

    return (
        <div className="min-h-500px">
            <div className="row">
                <div className="col-12 col-md-4">
                    <SummaryCard
                        label="Total Applicant"
                        value={data?.total_applicant ? data.total_applicant : 0}
                        icon="/media/icons/duotune/communication/com014.svg"
                        iconClassName="svg-icon-primary"
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <SummaryCard
                        label="Approved Applicant"
                        value={data?.approved_applicant ? data.approved_applicant : 0}
                        icon="/media/icons/duotune/communication/com014.svg"
                        iconClassName="svg-icon-success"
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <SummaryCard
                        label="Pending Applicant"
                        value={data?.pending_applicant ? data.pending_applicant : 0}
                        icon="/media/icons/duotune/communication/com014.svg"
                        iconClassName="svg-icon-gray"
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                    />
                </div>
                <div className="col-12 col-md-4 mt-6">
                    <SummaryCard
                        label="Rejected Applicant"
                        value={data?.rejected_applicant ? data.rejected_applicant : 0}
                        icon="/media/icons/duotune/communication/com014.svg"
                        iconClassName="svg-icon-danger"
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                    />
                </div>
                <div className="col-12 col-md-4 mt-6">
                    <SummaryCard
                        label="Approval Rate"
                        value={(data?.approval_rate ? data.approval_rate : 0) + '%'}
                        icon="/media/icons/duotune/arrows/arr070.svg"
                        iconClassName="svg-icon-success"
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                    />
                </div>
                <div className="col-12 col-md-4 mt-6">
                    <SummaryCard
                        label="Total Withdrawal"
                        value={toRupiah(data?.total_withdrawal ? data.total_withdrawal : 0)}
                        icon="/media/icons/duotune/finance/fin008.svg"
                        iconClassName="svg-icon-primary"
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                    />
                </div>
            </div>
            <div className="row mt-6">
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <h4 className="text-muted fw-bold mb-2">Start Date</h4>
                                    <h5 className="mb-0">{campaign.start_date}</h5>
                                </div>
                                <div className="col-12 col-md-4">
                                    <h4 className="text-muted fw-bold mb-2">End Date</h4>
                                    <h5 className="mb-0">{campaign.end_date}</h5>
                                </div>
                                <div className="col-12 col-md-4">
                                    <h4 className="text-muted fw-bold mb-2">Quota</h4>
                                    <h5 className="mb-0">{campaign.quota}</h5>
                                </div>
                            </div>
                            <div className="separator my-6" />
                            <div>
                                <h4 className="text-muted fw-bold mb-2">Description</h4>
                                {campaign.description ? <div dangerouslySetInnerHTML={{
                                    __html: campaign.description
                                }}></div> : '-'}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card sticky-none sticky-md-top" style={{
                        top: 80
                    }}>
                        <div className="card-body">
                            <h4 className="text-muted fw-bold">Allowed Courses</h4>
                            <div className="d-flex flex-column gap-6 mt-6">
                                {campaign.courses?.map(course => (
                                    <div className="d-flex align-items-center">
                                        <div className="symbol symbol-40px symbol-2by3">
                                            <div className="symbol-label" style={{
                                                backgroundImage: `url('${courseThumbnail(course.thumbnail_url)}')`
                                            }}></div>
                                        </div>
                                        <div className="ms-3">
                                            <p className="mb-0 fw-semibold text-justify">{course.title}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Authorization(Overview, ['admin', 'officer'])