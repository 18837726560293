import BooleanCell from "../../../../../../../../../components/Table/cells/Boolean"
import NumberCell from "../../../../../../../../../components/Table/cells/Number"
import TimeCell from "../../../../../../../../../components/Table/cells/Time"
import UserCell from "../../../../../../../../../components/Table/cells/User"
import ActionsCell from "./ActionsCell"
import MenuCell from "./MenuCell"
import TotalDurationCell from "./TotalDurationCell"


const participantColumns = [
    {
        Header: '#',
        accessor: 'number',
        disableSortBy: true,
        Cell: ({ ...props }) => <NumberCell {...props} />
    }, {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ ...props }) => <UserCell user={props.data[props.row.index]} />
    }, {
        Header: 'Total Duration',
        accessor: 'total_duration',
        Cell: ({ ...props }) => <TotalDurationCell totalDuration={props.session.lesson.duration} duration={props.data[props.row.index].total_duration} />
    }, {
        Header: 'First Join At',
        accessor: 'first_join_at',
        Cell: ({ ...props }) => <TimeCell time={props.data[props.row.index].first_join_at} />
    }, {
        Header: 'Is Verified',
        accessor: 'is_verified',
        Cell: ({ ...props }) => <BooleanCell isTrue={props.data[props.row.index].is_verified} />
    }, {
        Header: 'Is Present',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ ...props }) => <ActionsCell session={props.session} user={props.data[props.row.index]} onStatusChange={props.onStatusChange} />
    }, {
        Header: '',
        accessor: 'menu',
        className: 'text-end',
        disableSortBy: true,
        Cell: ({ ...props }) => <MenuCell user={props.data[props.row.index]} onLoginAsUser={props.onLoginAsUser} onShowZoomLogs={props.onShowZoomLogs} />
    }
]

const columnAliases = {
    number: 'id'
}

const getColumns = (sessionStatus) => {
    if (sessionStatus === 'finished') return participantColumns

    const activeParticipantColumns = []
    participantColumns.map(c => {
        if (['total_duration', 'first_join_at'].includes(c.accessor)) return true

        return activeParticipantColumns.push(c)
    })

    return activeParticipantColumns
}

export { getColumns, columnAliases }