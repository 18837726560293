import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'

import Dashboard from '../pages/dashboard/Dashboard'
import Categories from '../pages/courses/categories/Categories'
import CreateCategory from '../pages/courses/categories/CreateCategory'
import EditCategory from '../pages/courses/categories/EditCategory'
import Courses from '../pages/courses/manage/Courses'
import Course from '../pages/courses/manage/Course'
import CreateCourse from '../pages/courses/manage/CreateCourse'
import EditCourse from '../pages/courses/manage/EditCourse'
import EditCourseBasicInfo from '../pages/courses/manage/edit/BasicInfo'
import EditCoursePricing from '../pages/courses/manage/edit/Pricing'
import EditCourseRequirements from '../pages/courses/manage/edit/Requirements'
import EditCourseOutcomes from '../pages/courses/manage/edit/Outcomes'
import EditCourseOccupationUnits from '../pages/courses/manage/edit/OccupationUnits'
import EditCourseCompetencyUnits from '../pages/courses/manage/edit/CompetencyUnits'
import EditCourseCertificates from '../pages/courses/manage/edit/Certificates'
import EditCourseMedia from '../pages/courses/manage/edit/Media'
import EditCourseTanyaAI from '../pages/courses/manage/edit/TanyaAI'
import CourseCurriculum from '../pages/courses/manage/CourseCurriculum'
import CourseLiveSessions from '../pages/courses/manage/live_sessions/CourseLiveSessions'
import CourseMerchants from '../pages/courses/manage/merchants/CourseMerchants'
import CourseSchedules from '../pages/courses/manage/schedules/CourseSchedules'
import ManageVouchers from '../pages/courses/vouchers/ManageVouchers/ManageVouchers'
import CreateVoucher from '../pages/courses/vouchers/ManageVouchers/CreateVoucher'
import EditVoucher from '../pages/courses/vouchers/ManageVouchers/EditVoucher'
import GenerateHistory from '../pages/courses/vouchers/GenerateHistory/GenerateHistory'
import ImportReconciliation from '../pages/courses/vouchers/ImportReconciliation/ImportReconciliation'
import ImportFeedback from '../pages/courses/vouchers/ImportFeedback/ImportFeedback'
import ManageCoupons from '../pages/courses/coupons/ManageCoupons/ManageCoupons'
import CouponsBatch from '../pages/courses/coupons/CouponsBatch/CouponsBatch'
import EditCoupon from '../pages/courses/coupons/EditCoupon'
import CourseTasks from '../pages/courses/tasks/Tasks'
import Schedules from '../pages/courses/schedules/Schedules'
import Schedule from '../pages/courses/schedules/Schedule/Schedule'
import ScheduleOverview from '../pages/courses/schedules/Schedule/Overview'
import ScheduleSessions from '../pages/courses/schedules/Schedule/Sessions/Sessions'
import ScheduleSession from '../pages/courses/schedules/Schedule/Sessions/Session/Session'
import ScheduleSessionOverview from '../pages/courses/schedules/Schedule/Sessions/Session/Overview/Overview'
import ScheduleSessionAbsent from '../pages/courses/schedules/Schedule/Sessions/Session/Absent/Absent'
import ScheduleStudents from '../pages/courses/schedules/Schedule/Students/Students'
import ScheduleStudent from '../pages/courses/schedules/Schedule/Students/Student/Student'
import ScheduleTasks from '../pages/courses/schedules/Schedule/Tasks'
import ScheduleReports from '../pages/courses/schedules/Schedule/Reports'
import Sessions from '../pages/courses/sessions/Sessions'
import CheckProgress from '../pages/reports/CheckProgress/CheckProgress'
import Prakerja from '../pages/reports/Prakerja/Prakerja'
import ManageRoles from '../pages/roles/ManageRoles'
import RevenueStatistic from '../pages/statistics/Revenue/Revenue'
import WebsiteSettings from '../pages/settings/Website'
import Merchants from '../pages/merchants/Merchants'
import CreateMerchant from '../pages/merchants/CreateMerchant'
import EditMerchant from '../pages/merchants/EditMerchant'
import CreateWebsiteSetting from '../pages/settings/CreateSetting'
import EditSetting from '../pages/settings/components/EditSetting'
import ProductCategories from '../pages/products/categories/Categories'
import CreateProductCategory from '../pages/products/categories/CreateCategory'
import EditProductCategory from '../pages/products/categories/EditCategory'
import Products from '../pages/products/manage/Products'
import BlogCategories from '../pages/blog/categories/Categories'
import BlogPosts from '../pages/blog/posts/Posts'
import CreateBlogPost from '../pages/blog/posts/CreatePost'
import EditBlogPost from '../pages/blog/posts/EditPost'
import TunjanganHariKarya from '../pages/campaign/tunjangan-hari-karya/TunjanganHariKarya'
import TunjanganHariKaryaCard from '../pages/campaign/tunjangan-hari-karya/Card'
import TunjanganHariKaryaOverview from '../pages/campaign/tunjangan-hari-karya/Overview/Overview'
import TunjanganHariKaryaApplicants from '../pages/campaign/tunjangan-hari-karya/Applicants/Applicants'
import TunjanganHariKaryaWithdrawals from '../pages/campaign/tunjangan-hari-karya/Withdrawals/Withdrawals'
import Users from '../pages/users/Users'


const PrivateRoutes = () => {
    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard' />} />
                {/* Pages */}
                <Route path='dashboard' element={<Dashboard />} />

                <Route path='courses/categories' element={<Categories />} />
                <Route path='courses/categories/create' element={<CreateCategory />} />
                <Route path='courses/categories/:categoryId/edit' element={<EditCategory />} />
                <Route path='courses/manage' element={<Courses />} />
                <Route path='courses/manage/create' element={<CreateCourse />} />
                <Route path='courses/manage/:courseId' element={<Course />}>
                    <Route path='edit' element={<EditCourse />}>
                        <Route path='basic' element={<EditCourseBasicInfo />} />
                        <Route path='pricing' element={<EditCoursePricing />} />
                        <Route path='requirements' element={<EditCourseRequirements />} />
                        <Route path='outcomes' element={<EditCourseOutcomes />} />
                        <Route path='occupation_units' element={<EditCourseOccupationUnits />} />
                        <Route path='competency_units' element={<EditCourseCompetencyUnits />} />
                        <Route path='certificates' element={<EditCourseCertificates />} />
                        <Route path='media' element={<EditCourseMedia />} />
                        <Route path='tanya-ai' element={<EditCourseTanyaAI />} />
                    </Route>
                    <Route path='curriculum' element={<CourseCurriculum />} />
                    <Route path='schedules' element={<CourseSchedules />} />
                    <Route path='live_sessions' element={<CourseLiveSessions />} />
                    <Route path='merchants' element={<CourseMerchants />} />
                </Route>
                <Route path='courses/vouchers/manage' element={<ManageVouchers />} />
                <Route path='courses/vouchers/manage/create' element={<CreateVoucher />} />
                <Route path='courses/vouchers/manage/:voucherId/edit' element={<EditVoucher />} />
                <Route path='courses/vouchers/batch' element={<GenerateHistory />} />
                <Route path='courses/vouchers/import/reconciliation' element={<ImportReconciliation />} />
                <Route path='courses/vouchers/import/feedback' element={<ImportFeedback />} />
                <Route path='courses/coupons/manage' element={<ManageCoupons />} />
                <Route path='courses/coupons/batch' element={<CouponsBatch />} />
                <Route path='courses/tasks' element={<CourseTasks />} />
                <Route path='courses/schedules/all' element={<Schedules />} />
                <Route path='courses/schedules/:scheduleId' element={<Schedule />}>
                    <Route path='overview' element={<ScheduleOverview />} />
                    <Route path='sessions' element={<ScheduleSessions />} />
                    <Route path='sessions/:sessionId' element={<ScheduleSession />}>
                        <Route path='overview' element={<ScheduleSessionOverview />} />
                        <Route path='absent' element={<ScheduleSessionAbsent />} />
                    </Route>
                    <Route path='students' element={<ScheduleStudents />} />
                    <Route path='students/:studentId' element={<ScheduleStudent />} />
                    <Route path='tasks' element={<ScheduleTasks />} />
                    <Route path='reports' element={<ScheduleReports />} />
                </Route>
                <Route path='courses/schedules/sessions' element={<Sessions />} />

                <Route path='reports/progress' element={<CheckProgress />} />
                <Route path='reports/prakerja' element={<Prakerja />} />
                <Route path='roles' element={<ManageRoles />} />
                <Route path='statistics/revenue' element={<RevenueStatistic />} />
                <Route path='settings/website' element={<WebsiteSettings />} />
                <Route path='settings/website/create' element={<CreateWebsiteSetting />} />
                <Route path='settings/:settingId/edit' element={<EditSetting />} />
                <Route path='merchants' element={<Merchants />} />
                <Route path='merchants/create' element={<CreateMerchant />} />
                <Route path='merchants/:merchantId/edit' element={<EditMerchant />} />
                <Route path='coupons/:couponId/edit' element={<EditCoupon />} />
                <Route path='products/categories' element={<ProductCategories />} />
                <Route path='products/categories/create' element={<CreateProductCategory />} />
                <Route path='products/categories/:categoryId/edit' element={<EditProductCategory />} />
                <Route path='products/manage' element={<Products />} />
                {/* <Route path='products/manage/create' element={<CreateCourse />} />
                <Route path='products/manage/:productId' element={<Course />}>
                    <Route path='edit' element={<EditCourse />} />
                    <Route path='curriculum' element={<CourseCurriculum />} />
                </Route> */}

                <Route path='blog/categories' element={<BlogCategories />} />
                <Route path='blog/posts' element={<BlogPosts />} />
                <Route path='blog/posts/create' element={<CreateBlogPost />} />
                <Route path='blog/posts/:postId' element={<EditBlogPost />} />

                <Route path='campaign/tunjangan-hari-karya' element={<TunjanganHariKarya />} />
                <Route path='campaign/tunjangan-hari-karya/:campaignId' element={<TunjanganHariKaryaCard />}>
                    <Route path='overview' element={<TunjanganHariKaryaOverview />} />
                    <Route path='applicants' element={<TunjanganHariKaryaApplicants />} />
                    <Route path='withdrawals' element={<TunjanganHariKaryaWithdrawals />} />
                </Route>

                <Route path='users' element={<Users />} />

                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

export { PrivateRoutes }
