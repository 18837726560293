import { Helmet } from "react-helmet";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { getColumns, columnAliases } from "./components/ParticipantTable/_columns";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { generateZoomLogs, getScheduleSessionParticipants, updateScheduleSessionParticipant } from "../../../../../../../models/courses/schedules";
import { toast } from "react-toastify";
import { loginAsUser } from "../../../../../../../models/users";
import ParticipantFilter from "./components/ParticipantFilter";
import Table from "../../../../../../../components/Table/Table";
import ZoomLogsModal from "./components/ZoomLogsModal";
import Spinner from "../../../../../../../components/Spinner/Spinner";
import GeneralPagination from "../../../../../../../components/Pagination/GeneralPagination";


export default function Absent() {
    const { scheduleId, sessionId } = useParams()
    const { schedule, session } = useOutletContext()

    const participantsQueryKey = `schedule-${scheduleId}-session-${session.id}-participants`
    const columns = getColumns(session.status)
    const pageSize = 100
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({})
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})
    const [showZoomLogsModal, setShowZoomLogsModal] = useState(false)
    const [zoomLogsRegistrantId, setZoomLogsRegistrantId] = useState(null)

    const queryClient = useQueryClient()

    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery([participantsQueryKey], () => getScheduleSessionParticipants({
        scheduleId,
        sessionId: session.id,
        keyword: filters?.keyword,
        status: filters?.status,
        verified_status: filters?.verified_status,
        page,
        rows: pageSize,
        sortBy
    }))

    const { mutate: mutateSessionUser } = useMutation(data => updateScheduleSessionParticipant({ scheduleId, sessionId: session.id, ...data }), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([participantsQueryKey])
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    const { mutate: generateZoomLogsMutate, isLoading: generateZoomLogsLoading } = useMutation(() => generateZoomLogs(scheduleId, session.id), {
        onSuccess: () => {
            setShowZoomLogsModal(true)
            queryClient.invalidateQueries([participantsQueryKey])
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    const { mutate: loginAsUserMutate } = useMutation(userId => loginAsUser({ userId }), {
        onSuccess: data => window.open(data.data, '_newtab'),
        onError: error => toast.error(error?.response?.data?.message)
    })

    useEffect(() => {
        queryClient.fetchQuery([participantsQueryKey])
    }, [queryClient, filters, page, sortBy])

    return (
        <>
            <Helmet>
                <title>{`Absent - ${session?.lesson?.title}`}</title>
            </Helmet>
            <div className="card mt-6">
                <div className="card-header d-block p-6">
                    <div className="d-flex">
                        <button type="button" className={"btn btn-sm me-3 " + (showFilter ? 'btn-primary' : 'btn-light-primary')} onClick={() => setShowFilter(item => !item)}><i className="fa fa-filter"></i> Filter</button>
                        {session.status === 'finished' && (
                            <button type="button" className="btn btn-sm btn-light-dark" disabled={generateZoomLogsLoading} onClick={() => {
                                setZoomLogsRegistrantId(null)
                                generateZoomLogsMutate()
                            }}>Zoom Meeting Logs {generateZoomLogsLoading && <Spinner size="sm" className="ms-1 text-black" />}</button>
                        )}
                    </div>
                    <ParticipantFilter
                        show={showFilter}
                        onSubmit={values => {
                            setPage(1)
                            setFilters(values)
                        }}
                    />
                </div>
                <div className="card-body">
                    <Table
                        columns={columns}
                        isLoading={isFetching}
                        isError={isError}
                        error={error}
                        data={!isLoading ? data.data : []}
                        currentPage={page}
                        pageSize={pageSize}
                        onSortByChange={sortBy => setSortBy(() => {
                            if (sortBy.length) return {
                                id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                direction: sortBy[0].desc ? 'DESC' : 'ASC'
                            }
                    
                            return {}
                        })}
                        session={session}
                        onStatusChange={data => mutateSessionUser(data)}
                        onLoginAsUser={userId => loginAsUserMutate(userId)}
                        onShowZoomLogs={registrantId => {
                            setZoomLogsRegistrantId(registrantId)
                            generateZoomLogsMutate()
                        }}
                    />
                    
                    {isSuccess && <GeneralPagination
                        onPageChange={page => setPage(page)}
                        totalCount={data?.meta.total}
                        currentPage={page}
                        pageSize={pageSize}
                        from={data?.meta.from}
                        to={data?.meta.to}
                    />}
                </div>
            </div>

            <ZoomLogsModal show={showZoomLogsModal} onHide={() => setShowZoomLogsModal(false)} scheduleId={scheduleId} sessionId={session.id} registrantId={zoomLogsRegistrantId} />
        </>
    )
}