import BooleanCell from "../../../../../../../components/Table/cells/Boolean"
import NumberCell from "../../../../../../../components/Table/cells/Number"
import ActionsCell from "./ActionsCell"
import UserCell from "./UserCell"


const studentColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        className: 'ps-2',
        Cell: ({ ...props }) => <NumberCell {...props} />
    }, {
        Header: 'User',
        accessor: 'name',
        Cell: ({ ...props }) => <UserCell user={props.data[props.row.index]} onPhoneNumberClick={props.onPhoneNumberClick} />
    }, {
        Header: 'Merchant',
        accessor: 'merchant'
    }, {
        Header: 'Voucher Code',
        accessor: 'voucher_code'
    }, {
        Header: 'Redeem Code',
        accessor: 'redeem_code',
        Cell: ({ ...props }) => props.data[props.row.index].redeem_code || '-'
    }, {
        Header: 'Invoice Code',
        accessor: 'invoice_code',
        Cell: ({ ...props }) => props.data[props.row.index].invoice_code || '-'
    }, {
        Header: 'Redeem Status',
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ ...props }) => <BooleanCell isTrue={props.data[props.row.index].status === 'used'} />
    }, {
        Header: 'Progress',
        accessor: 'progress',
        disableSortBy: true,
        Cell: ({ ...props }) => props.data[props.row.index].progress + '%'
    }, {
        Header: 'Pre Test score',
        accessor: 'pretest_score',
        Cell: ({ ...props }) => (props.data[props.row.index].pretest_score || props.data[props.row.index].pretest_score === 0) ? props.data[props.row.index].pretest_score : '-'
    }, {
        Header: 'Post Test score',
        accessor: 'score',
        Cell: ({ ...props }) => (props.data[props.row.index].score || props.data[props.row.index].score === 0) ? props.data[props.row.index].score : '-'
    }, {
        Header: 'Skill Test score',
        accessor: 'skill_test_score',
        Cell: ({ ...props }) => (props.data[props.row.index].skill_test_score || props.data[props.row.index].skill_test_score === 0) ? props.data[props.row.index].skill_test_score : '-'
    }, {
        Header: 'Redemption Report',
        accessor: 'redemption_report_at',
        disableSortBy: true,
        Cell: ({ ...props }) => <BooleanCell isTrue={!!props.data[props.row.index].redemption_report_at} />
    }, {
        Header: 'Completion Report',
        accessor: 'completion_report_at',
        disableSortBy: true,
        Cell: ({ ...props }) => <BooleanCell isTrue={!!props.data[props.row.index].completion_report_at} />
    }, {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ ...props }) => <ActionsCell scheduleId={props.scheduleId} student={props.data[props.row.index]} onLoginAsUser={props.onLoginAsUser} onSetSkillTestScore={props.onSetSkillTestScore} />
    }
]

const columnAliases = {
    number: 'id'
}

export { studentColumns, columnAliases }