import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { toast } from "react-toastify"
import clsx from "clsx"

import { useAuth } from "../../../../modules/auth"
import { checkIsActive } from "../../../../../_metronic/helpers"
import { getSchedule } from "../../../../models/courses/schedules"
import PageSpinner from "../../../../components/Spinner/PageSpinner"
import PageError from "../../../../components/Error/PageError"


const MenuListItem = ({ to, children }) => {
    const { pathname } = useLocation()

    return (
        <li className="nav-item mt-2">
            <Link
                className={clsx("nav-link text-active-primary ms-0 me-10 py-5", {
                    "active": checkIsActive(pathname, to)
                })}
                to={to}
            >
                {children}
            </Link>
        </li>
    )
}

export default function Schedule() {
    const { scheduleId } = useParams()
    const navigate = useNavigate()
    const { currentUser: { role_id: roleId } } = useAuth()

    const { isLoading, isError, error, data: { data: schedule } = {} } = useQuery(`schedule-${scheduleId}`, () => getSchedule(scheduleId), {
        retry: 0,
        onError: error => {
            if (error.response.status === 404) {
                toast.error('Schedule not found')
                navigate('/schedules/all')
            }
        }
    })

    return isLoading ? <PageSpinner height={300} /> : isError ? (
        <PageError height={300}>{error.message}</PageError>
    ) : (
        <>
            <div className="card">
                <div className="card-body pt-9 pb-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <p className="mb-1 text-primary">{schedule.schedule_code}</p>
                            <h3 className="mb-1">{schedule.name}</h3>
                            <p className="mb-0 text-muted">{schedule?.course?.title}</p>
                        </div>
                        <div>
                            {schedule.status === 'inactive' ? <span className="badge badge-square badge-lg badge-light p-3">Upcoming</span> : null}
                            {schedule.status === 'active' ? <span className="badge badge-square badge-lg badge-light-primary p-3">On Going</span> : null}
                            {schedule.status === 'finished' ? <span className="badge badge-square badge-lg badge-light-primary p-3">Finished</span> : null}
                        </div>
                    </div>
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                        <MenuListItem
                            to={`/courses/schedules/${schedule.id}/overview`}
                        >
                            Overview
                        </MenuListItem>
                        <MenuListItem
                            to={`/courses/schedules/${schedule.id}/sessions`}
                        >
                            Sessions
                        </MenuListItem>
                        <MenuListItem
                            to={`/courses/schedules/${schedule.id}/students`}
                        >
                            Students
                        </MenuListItem>
                        <MenuListItem
                            to={`/courses/schedules/${schedule.id}/tasks`}
                        >
                            Tasks
                        </MenuListItem>
                        {['admin', 'officer'].includes(roleId) && (
                            <MenuListItem
                                to={`/courses/schedules/${schedule.id}/reports`}
                            >
                                Reports
                            </MenuListItem>
                        )}
                    </ul>
                </div>
            </div>
            <div className="mt-6">
                <Outlet context={{ schedule }} />
            </div>
        </>
    )
}
