import { useFormik } from "formik"
import Collapse from "react-bootstrap/Collapse"


const UserFilter = ({ show, onSubmit }) => {
    const formik = useFormik({
        initialValues: {
            name: '',
            email: ''
        },
        onSubmit
    })

    return (
        <Collapse in={show}>
            <div className="border rounded mt-6">
                <form className="row p-6" onSubmit={formik.handleSubmit}>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label">
                                <small>Name</small>
                            </label>
                            <input type="text" name="name" className="form-control form-control-sm" onChange={formik.handleChange} value={formik.values.name} />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label">
                                <small>Email</small>
                            </label>
                            <input type="text" name="email" className="form-control form-control-sm" onChange={formik.handleChange} value={formik.values.email} />
                        </div>
                    </div>
                    <div className="col-12 text-end mt-6">
                        {formik.dirty && (
                            <button type="button" className="btn btn-sm btn-outline btn-outline-danger me-3" onClick={() => {
                                formik.resetForm()
                                onSubmit({})
                            }}>Clear</button>
                        )}
                        <button type="submit" className="btn btn-sm btn-primary">Apply</button>
                    </div>
                </form>
            </div>
        </Collapse>
    )
}

export default UserFilter