import { useEffect, useState } from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { getUsers, loginAsUser } from "../../models/users"
import UserFilter from "./components/UserFilter"
import { userColumns, columnAliases } from "./components/UserTable/_columns"
import GeneralPagination from "../../components/Pagination/GeneralPagination"
import Table from "../../components/Table/Table"
import { toast } from "react-toastify"
import ResetPasswordModal from "./components/ResetPasswordModal"
import Authorization from "../../routing/Authorization"

const Users = () => {
    const queryKey = 'users'

    const pageSize = 25
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({
        id: "created_at",
        direction: "DESC"
    })

    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
    const [resetPasswordData, setResetPasswordData] = useState({})

    const queryClient = useQueryClient()
    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery([queryKey], () => getUsers({
        isMentor: filters?.is_mentor,
        name: filters?.name,
        email: filters?.email,
        page,
        rows: pageSize,
        sortBy
    }))

    const { mutate: loginAsUserMutate } = useMutation(userId => loginAsUser({ userId }), {
        onSuccess: data => window.open(data.data, '_newtab'),
        onError: error => toast.error(error?.response?.data?.message)
    })

    useEffect(() => {
        queryClient.fetchQuery(queryKey)
    }, [queryClient, filters, page, sortBy])

    return <>
        <div className="min-h-500px">
            <PageTitle>Users</PageTitle>
            <div className="card">
                <div className="card-header p-6 d-block">
                    <div className="d-flex justify-content-between">
                        <button type="button" className={"btn btn-sm me-3 " + (showFilter ? 'btn-primary' : 'btn-light-primary')} onClick={() => setShowFilter(item => !item)}><i className="fa fa-filter"></i> Filter</button>
                    </div>
                    <UserFilter
                        defaultValues={filters}
                        show={showFilter}
                        queryClient={queryClient}
                        onSubmit={values => {
                            setPage(1)
                            setFilters(values)
                        }}
                    />
                </div>
                <div className="card-body p-6">
                    <Table
                        columns={userColumns}
                        isLoading={isFetching}
                        isError={isError}
                        error={error}
                        data={!isLoading ? data.data : []}
                        currentPage={page}
                        pageSize={pageSize}
                        defaultSortBy={{
                            id: 'created_at',
                            desc: true
                        }}
                        onSortByChange={sortBy => setSortBy(() => {
                            if (sortBy.length) return {
                                id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                direction: sortBy[0].desc ? 'DESC' : 'ASC'
                            }
                    
                            return {}
                        })}
                        onLoginAsUser={userId => loginAsUserMutate(userId)}
                        onResetPassword={user => {
                            setResetPasswordData(user)
                            setShowResetPasswordModal(true)
                        }}
                    />
                    
                    {isSuccess && <GeneralPagination
                        onPageChange={page => setPage(page)}
                        totalCount={data?.meta.total}
                        currentPage={page}
                        pageSize={pageSize}
                        from={data?.meta.from}
                        to={data?.meta.to}
                    />}
                </div>
            </div>
        </div>

        <ResetPasswordModal
            show={showResetPasswordModal}
            onHide={() => {
                setShowResetPasswordModal(false)
                setResetPasswordData({})
            }}
            user={resetPasswordData}
        />
    </>
}

export default Authorization(Users, ['admin', 'officer', 'host', 'customer_service'])