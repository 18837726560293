const StatusCell = ({ status = null }) => {
    if (!status) return '-'

    const statuses = {
        inactive: {
            className: 'badge-light-secondary',
            name: 'Inactive'
        }, active: {
            className: 'badge-light-success',
            name: 'Active'
        }
    }

    return <span className={"text-capitalize badge fw-bolder " + statuses[status].className}>{statuses[status].name}</span>
}

export default StatusCell