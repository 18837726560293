const StatusCell = ({ status, withdrawStatus = null }) => {
    if (!status) return '-'

    const statuses = {
        pending: {
            className: 'badge-secondary',
            name: 'Pending'
        }, approved: {
            className: 'badge-success',
            name: 'Approved'
        }, rejected: {
            className: 'badge-light-danger',
            name: 'Rejected'
        }, resubmit: {
            className: 'badge-light-warning',
            name: 'Resubmit'
        }, success: {
            className: 'badge-success',
            name: 'Success'
        }, failed: {
            className: 'badge-light-danger',
            name: 'Failed'
        },
    }

    return (
        <div className="d-flex gap-2">
            <span className={"text-capitalize badge " + statuses[status].className}>{statuses[status].name}</span>
            {withdrawStatus && <span className={"text-capitalize badge " + statuses[withdrawStatus].className}>Withdraw {statuses[withdrawStatus].name}</span>}
        </div>
    )
}

export default StatusCell