export const voucherStatusVariant = {
    available: 'success',
    used: 'dark',
    expired: 'danger',
}

export const voucherTypeVariant = {
    live: 'success',
    dummy: 'light'
}

export const popupQuizTypes = {
    drag_the_words: 'Drag The Words',
    word_search: 'Word Search',
    problem_solving: 'Problem Solving',
    true_or_false: 'True or False',
    sequence: 'Sequence',
    fill_the_blank: 'Fill the Blank',
    sorting: 'Sorting'
}