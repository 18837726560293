import clsx from "clsx"
import { useOutletContext } from "react-router-dom"
import { useQuery } from "react-query"

import { formatDate } from "../../../../helpers/common"
import SummaryCard from "../../../dashboard/components/SummaryCard"
import { getScheduleStatistic } from "../../../../models/courses/schedules"
import { Helmet } from "react-helmet"


const Items = ({ title, value, className }) => (
    <div className={clsx("d-flex justify-content-between gap-3", className)}>
        <p className="mb-0 fw-bold">{title}</p>
        <p className="mb-0 text-end">{value}</p>
    </div>
)

const Statistic = ({ schedule }) => {
    const { isLoading, isError, error, data: { data } = {} } = useQuery('schedule-statistic', () => getScheduleStatistic(schedule.id))

    return (
        <div className="row">
            <div className="col-6 col-md-4">
                <SummaryCard
                    label="Transactions"
                    value={data?.transactions ? data.transactions : 0}
                    icon="/media/icons/duotune/communication/com006.svg"
                />
            </div>
            <div className="col-6 col-md-4">
                <SummaryCard
                    label="Students"
                    value={data?.students ? data.students : 0}
                    icon="/media/icons/duotune/communication/com006.svg"
                    iconClassName="svg-icon-success"
                    isLoading={isLoading}
                    isError={isError}
                    error={error}
                />
            </div>
            <div className="col-6 col-md-4 mt-6 mt-md-0">
                <SummaryCard
                    label="Total Not Redeem"
                    value={data?.not_redeem ? data.not_redeem : 0}
                    icon="/media/icons/duotune/communication/com006.svg"
                    iconClassName="svg-icon-danger"
                    isLoading={isLoading}
                    isError={isError}
                    error={error}
                />
            </div>
            <div className="col-6 col-md-4 mt-6">
                <SummaryCard
                    label="Completed"
                    value={data?.completed ? data.completed : 0}
                    icon="/media/icons/duotune/arrows/arr016.svg"
                    iconClassName="svg-icon-success"
                    isLoading={isLoading}
                    isError={isError}
                    error={error}
                />
            </div>
            <div className="col-6 col-md-4 mt-6">
                <SummaryCard
                    label="Incomplete"
                    value={data?.incomplete ? data.incomplete : 0}
                    icon="/media/icons/duotune/abstract/abs019.svg"
                    iconClassName="svg-icon-gray-700"
                    isLoading={isLoading}
                    isError={isError}
                    error={error}
                />
            </div>
            <div className="col-6 col-md-4 mt-6">
                <SummaryCard
                    label="Completion Rate"
                    value={(data?.completion_rate ? data.completion_rate : 0) + '%'}
                    icon="/media/icons/duotune/arrows/arr070.svg"
                    iconClassName="svg-icon-success"
                    isLoading={isLoading}
                    isError={isError}
                    error={error}
                />
            </div>
        </div>
    )
}

export default function Overview() {
    const { schedule } = useOutletContext()

    return (
        <>
            <Helmet>
                <title>Overview - {schedule.schedule_code} - {schedule.course?.title}</title>
            </Helmet>
            <div className="min-h-500px">
                <div className="card">
                    <div className="card-body">
                        <Items className="mb-4" title="Schedule" value={schedule.name} />
                        <Items className="mb-4" title="Schedule Code" value={schedule.schedule_code} />
                        <Items className="mb-4" title="Start Date" value={formatDate(schedule.schedule_start, false)} />
                        <Items className="mb-4" title="End Date" value={formatDate(schedule.schedule_end, false)} />
                        <Items className="mb-4" title="Course" value={schedule.course?.title} />
                        <Items className="mb-4" title="Mentor" value={schedule.course?.main_mentor?.name} />
                        {schedule.zoom_user && <Items className="mb-0" title="Host" value={schedule.zoom_user?.email} />}
                    </div>
                </div>
                <div className="mt-6">
                    <Statistic schedule={schedule} />
                </div>
            </div>
        </>
    )
}
