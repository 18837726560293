import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { useMutation } from "react-query"
import { toast } from "react-toastify"

import { saveLesson } from "../../../../../models/courses/courses"
import ChooseLessonType from "./ChooseLessonType"
import LessonTypeDocument from "./LessonTypeDocument"
import LessonTypeOfflineActivity from "./LessonTypeOfflineActivity"
import LessonTypePracticalTask from "./LessonTypePracticalTask"
import LessonTypeQuiz from "./LessonTypeQuiz"
import LessonTypeVideo from "./LessonTypeVideo"
import LessonTypeWebinar from "./LessonTypeWebinar"
import LessonTypePopupQuizDragTheWords from "./LessonTypePopupQuizDragTheWords"
import LessonTypeReflectiveJournal from "./LessonTypeReflectiveJournal"
import LessonTypePopupQuizWordSearch from "./LessonTypePopupQuizWordSearch"
import LessonTypePopupQuizProblemSolving from "./LessonTypePopupQuizProblemSolving"


const lessonTypes = {
    video: 'Video',
    document: 'Document',
    quiz: 'Quiz',
    practical_task: 'Practical Task',
    webinar: 'Webinar',
    offline_activity: 'Offline Activity',
    reflective_journal: 'Reflective Journal',
    popup_quiz_drag_the_words: 'Popup Quiz - Drag The Words',
    popup_quiz_word_search: 'Popup Quiz - Word Search',
    popup_quiz_problem_solving: 'Popup Quiz - Problem Solving',
}

const CreateLessonModal = ({ queryClient, section, show, onHide }) => {
    const [step, setStep] = useState('lesson_type')
    const [lessonType, setLessonType] = useState()

    const { mutate, isLoading } = useMutation(lesson => saveLesson(section.course_id, section.id, lesson), {
        onSuccess: () => {
            queryClient.invalidateQueries([`course-${section.course_id}-sections`])
            toast.success('Success')
            hideModal()
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    useEffect(() => {
        if (step === 'lesson_type') {
            setLessonType()
        }
    }, [step])

    const hideModal = () => {
        setStep('lesson_type')
        setLessonType()
        onHide()
    }

    return (
        <Modal show={show} onHide={hideModal} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Create Lesson</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="rounded border p-3 bg-light-primary">
                    <p className="mb-0 fw-semibold">Section: {section.title}</p>
                </div>
                <div className="separator my-6"></div>
                {step === 'lesson_type' ? (
                    <ChooseLessonType onContinue={lessonType => {
                        setLessonType(lessonType)
                        setStep('content')
                    }} />
                ) : step === 'content' ? (
                    <>
                        <div className="d-flex justify-content-between align-items-center mb-6">
                            <div>
                                <p className="mb-1 fw-bold">Lesson Type</p>
                                <p className="mb-0 fw-semibold text-primary text-capitalize">{lessonTypes[lessonType]}</p>
                            </div>
                            <button type="button" className="btn btn-sm btn-light text-primary" onClick={() => setStep('lesson_type')}>Change</button>
                        </div>
                        <div className="separator my-6"></div>
                        {lessonType === 'video' ? (
                            <LessonTypeVideo
                                onSubmit={lesson => mutate(lesson)}
                                isSubmitLoading={isLoading}
                            />
                        ) : lessonType === 'document' ? (
                            <LessonTypeDocument
                                onSubmit={lesson => mutate(lesson)}
                                isSubmitLoading={isLoading}
                            />
                        ) : lessonType === 'quiz' ? (
                            <LessonTypeQuiz
                                onSubmit={lesson => mutate(lesson)}
                                isSubmitLoading={isLoading}
                            />
                        ) : lessonType === 'practical_task' ? (
                            <LessonTypePracticalTask
                                onSubmit={lesson => mutate(lesson)}
                                isSubmitLoading={isLoading}
                            />
                        ) : lessonType === 'webinar' ? (
                            <LessonTypeWebinar
                                onSubmit={lesson => mutate(lesson)}
                                isSubmitLoading={isLoading}
                            />
                        ) : lessonType === 'offline_activity' ? (
                            <LessonTypeOfflineActivity
                                onSubmit={lesson => mutate(lesson)}
                                isSubmitLoading={isLoading}
                            />
                        ) : lessonType === 'reflective_journal' ? (
                            <LessonTypeReflectiveJournal
                                onSubmit={lesson => mutate(lesson)}
                                isSubmitLoading={isLoading}
                            />
                        ) : lessonType === 'popup_quiz_drag_the_words' ? (
                            <LessonTypePopupQuizDragTheWords
                                onSubmit={lesson => mutate(lesson)}
                                isSubmitLoading={isLoading}
                            />
                        ) : lessonType === 'popup_quiz_word_search' ? (
                            <LessonTypePopupQuizWordSearch
                                onSubmit={lesson => mutate(lesson)}
                                isSubmitLoading={isLoading}
                            />
                        ) : lessonType === 'popup_quiz_problem_solving' ? (
                            <LessonTypePopupQuizProblemSolving
                                onSubmit={lesson => mutate(lesson)}
                                isSubmitLoading={isLoading}
                            />
                        ) : null}
                    </>
                ) : null}
            </Modal.Body>
        </Modal>
    )
}

export default CreateLessonModal