import { useFormik } from "formik"
import Collapse from "react-bootstrap/Collapse"


const WithdrawFilter = ({ show, onSubmit }) => {
    const formik = useFormik({
        initialValues: {
            full_name: '',
            status: 'pending',
            ewallet: '',
            ewallet_address: '',
        },
        onSubmit
    })

    return (
        <Collapse in={show}>
            <div className="border rounded mt-6">
                <form className="row p-6" onSubmit={formik.handleSubmit}>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label">
                                <small>Full Name</small>
                            </label>
                            <input type="text" name="full_name" className="form-control form-control-sm" onChange={formik.handleChange} value={formik.values.full_name} />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label">
                                <small>E Wallet Address</small>
                            </label>
                            <input type="text" name="ewallet_address" className="form-control form-control-sm" onChange={formik.handleChange} value={formik.values.ewallet_address} />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label"><small>Status</small></label>
                            <select name="status" className="form-select form-select-sm" onChange={formik.handleChange} value={formik.values.status}>
                                <option value="">All</option>
                                <option value="pending">Pending</option>
                                <option value="success">Success</option>
                                <option value="failed">Failed</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label">
                                <small>E Wallet</small>
                            </label>
                            <select name="ewallet" className="form-select form-select-sm" onChange={formik.handleChange} value={formik.values.ewallet}>
                                <option value="">All</option>
                                <option value="gopay">GoPay</option>
                                <option value="dana">DANA</option>
                                <option value="linkaja">LinkAja</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 text-end mt-6">
                        {formik.dirty && (
                            <button type="button" className="btn btn-sm btn-outline btn-outline-danger me-3" onClick={() => {
                                formik.resetForm()
                                onSubmit({})
                            }}>Clear</button>
                        )}
                        <button type="submit" className="btn btn-sm btn-primary">Apply</button>
                    </div>
                </form>
            </div>
        </Collapse>
    )
}

export default WithdrawFilter