import { useEffect, useState } from "react"
import { useQueryClient, useQuery } from "react-query"
import { useNavigate } from "react-router-dom"

import { PageTitle } from "../../../../_metronic/layout/core"
import Table from "../../../components/Table/Table"
import GeneralPagination from "../../../components/Pagination/GeneralPagination"

import { getCourseAllSessions } from "../../../models/courses/courses"
import SessionFilter from "./components/SessionFilter"
import { sessionColumns, columnAliases } from "./components/SessionTable/_columns"
import SessionDetailModal from "../manage/schedules/components/SessionModal/SessionDetailmodal"
import { Helmet } from "react-helmet"


const Sessions = () => {
    const navigate = useNavigate()

    const pageSize = 10
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({
        status: {
            label: 'On Going',
            value: 'active'
        },
        have_schedule_code: 1
    })
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({
        id: 'start_at',
        direction: 'ASC'
    })

    const [showSessionDetailModal, setShowSessionDetailModal] = useState(false)
    const [sessionDetailData, setSessionDetailData] = useState(null)

    const queryClient = useQueryClient()
    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery(['course-all-sessions'], () => getCourseAllSessions({
        courseId: filters?.course?.value,
        scheduleId: filters?.schedule?.value,
        userId: filters?.mentor?.value,
        startAt: filters?.start_at?.value,
        status: filters?.status?.value,
        haveScheduleCode: filters?.have_schedule_code,
        page,
        rows: pageSize,
        sortBy
    }))

    useEffect(() => {
        queryClient.fetchQuery('course-all-sessions')
    }, [queryClient, filters, page, sortBy])

    return (
        <>
            <Helmet>
                <title>All Sessions</title>
            </Helmet>
            <div className="min-h-500px">
                <PageTitle>All Sessions</PageTitle>
                <div className="card">
                    <div className="card-header p-6 d-block">
                        <div className="d-flex justify-content-between">
                            <button type="button" className={"btn btn-sm me-3 " + (showFilter ? 'btn-primary' : 'btn-light-primary')} onClick={() => setShowFilter(item => !item)}><i className="fa fa-filter"></i> Filter</button>
                        </div>
                        <SessionFilter
                            defaultValues={filters}
                            show={showFilter}
                            queryClient={queryClient}
                            onSubmit={values => {
                                setPage(1)
                                setFilters(values)
                            }}
                        />
                    </div>
                    <div className="card-body p-6">
                        <Table
                            isClickableRow
                            columns={sessionColumns}
                            isLoading={isFetching}
                            isError={isError}
                            error={error}
                            data={!isLoading ? data.data : []}
                            currentPage={page}
                            pageSize={pageSize}
                            defaultSortBy={{
                                id: 'start_at',
                                desc: false
                            }}
                            onSortByChange={sortBy => setSortBy(() => {
                                if (sortBy.length) return {
                                    id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                    direction: sortBy[0].desc ? 'DESC' : 'ASC'
                                }
                        
                                return {}
                            })}
                            onRowClick={data => {
                                navigate(`/courses/schedules/${data.schedule_id}/sessions/${data.id}/absent`)
                            }}
                        />
                        
                        {isSuccess && <GeneralPagination
                            onPageChange={page => setPage(page)}
                            totalCount={data?.meta.total}
                            currentPage={page}
                            pageSize={pageSize}
                            from={data?.meta.from}
                            to={data?.meta.to}
                        />}
                    </div>
                </div>
            </div>
            <SessionDetailModal
                queryClient={queryClient}
                schedule={sessionDetailData?.schedule}
                session={sessionDetailData}
                courseId={sessionDetailData?.course_id}
                show={showSessionDetailModal}
                onHide={() => {
                    setShowSessionDetailModal(false)
                    setSessionDetailData(null)
                }}
            />
        </>
    )
}

export default Sessions