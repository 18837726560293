import { useMutation, useQuery, useQueryClient } from "react-query"
import { useEffect, useState } from "react"

import { PageTitle } from "../../../../_metronic/layout/core"
import Authorization from "../../../routing/Authorization"
import SummaryCard from "../../dashboard/components/SummaryCard"
import { deleteCampaign, getCampaignStatistics, getCampaigns } from "../../../models/tunjangan-hari-karya"
import CampaignFilter from "./components/CampaignFilter"
import GeneralPagination from "../../../components/Pagination/GeneralPagination"
import { campaignColumns, columnAliases } from "./components/CampaignTable/_columns"
import Table from "../../../components/Table/Table"
import DeleteConfirmModal from "../../../components/DeleteConfirmModal"
import { formatDate, toRupiah } from "../../../helpers/common"
import { toast } from "react-toastify"
import EditCampaignModal from "./components/EditCampaignModal"
import CreateCampaignModal from "./components/CreateCampaignModal"

const TunjanganHariKarya = () => {
    const queryKey = 'thk-campaigns'

    const pageSize = 10
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({
        id: 'start_date',
        direction: 'desc'
    })
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [editData, setEditData] = useState({})
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteData, setDeleteData] = useState({})

    const queryClient = useQueryClient()
    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery([queryKey], () => getCampaigns({
        status: filters?.status,
        formStatus: filters?.formStatus,
        page,
        rows: pageSize,
        sortBy
    }))

    const { isLoading: statisticIsLoading, isError: statisticIsError, error: statisticError, data: statistic } = useQuery([`${queryKey}-statistic`], () => getCampaignStatistics())

    const { mutate: deleteCampaignMutate, isLoading: deleteCampaignIsLoading } = useMutation(campaignId => deleteCampaign(campaignId), {
        onSuccess: () => {
            toast.success("Campaign deleted")
            queryClient.invalidateQueries([queryKey])
            queryClient.invalidateQueries([`${queryKey}-statistic`])
        },
        onError: () => toast.error("Failed to delete campaign")
    })

    useEffect(() => {
        queryClient.fetchQuery([queryKey])
    }, [queryClient, filters, page, sortBy])

    return <>
        <div className="min-h-450px">
            <PageTitle>Tunjangan Hari Karya</PageTitle>
            <div className="row">
                <div className="col-12 col-md-4">
                    <SummaryCard label="Total Campaign" value={statistic?.total_campaign} icon="/media/icons/duotune/ecommerce/ecm007.svg" isLoading={statisticIsLoading} isError={statisticIsError} error={statisticError} />
                </div>
                <div className="col-12 col-md-4">
                    <SummaryCard label="Total Applicant" value={statistic?.total_applicant} icon="/media/icons/duotune/ecommerce/ecm007.svg" isLoading={statisticIsLoading} isError={statisticIsError} error={statisticError} />
                </div>
                <div className="col-12 col-md-4">
                    <SummaryCard label="Total Withdrawal" value={toRupiah(statistic?.total_withdrawal)} icon="/media/icons/duotune/ecommerce/ecm007.svg" isLoading={statisticIsLoading} isError={statisticIsError} error={statisticError} />
                </div>
            </div>
            <div className="card mt-6">
                <div className="card-header p-6 d-block">
                    <div className="d-flex justify-content-between">
                        <button type="button" className={"btn btn-sm " + (showFilter ? 'btn-primary' : 'btn-light-primary')} onClick={() => setShowFilter(item => !item)}><i className="fa fa-filter"></i> Filter</button>
                        <button type="button" className="btn btn-sm btn-primary" onClick={() => setShowCreateModal(true)}>Create Campaign</button>
                    </div>
                    <CampaignFilter
                        show={showFilter}
                        onSubmit={values => {
                            setPage(1)
                            setFilters(values)
                        }}
                    />
                </div>
                <div className="card-body p-6">
                    <Table
                        columns={campaignColumns}
                        isLoading={isFetching}
                        isError={isError}
                        error={error}
                        data={!isLoading ? data.data : []}
                        currentPage={page}
                        pageSize={pageSize}
                        defaultSortBy={{
                            id: 'start_date',
                            desc: true
                        }}
                        onSortByChange={sortBy => setSortBy(() => {
                            if (sortBy.length) return {
                                id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                direction: sortBy[0].desc ? 'DESC' : 'ASC'
                            }
                    
                            return {}
                        })}
                        onAllowedCoursesClick={(courseIds) => alert(`Course IDS: ${courseIds.join(', ')}`)}
                        onEdit={campaign => {
                            setEditData(campaign)
                            setShowEditModal(true)
                        }}
                        onDelete={campaign => {
                            setDeleteData(campaign)
                            setShowDeleteModal(true)
                        }}
                    />
                    
                    {isSuccess && <GeneralPagination
                        onPageChange={page => setPage(page)}
                        totalCount={data?.meta.total}
                        currentPage={page}
                        pageSize={pageSize}
                        from={data?.meta.from}
                        to={data?.meta.to}
                    />}
                </div>
            </div>
        </div>
        
        <CreateCampaignModal
            queryClient={queryClient}
            queryKey={queryKey}
            show={showCreateModal}
            onHide={() => setShowCreateModal(false)}
        />
        <EditCampaignModal
            queryClient={queryClient}
            queryKey={queryKey}
            campaign={editData}
            show={showEditModal}
            onHide={() => {
                setShowEditModal(false)
                setEditData({})
            }}
        />

        <DeleteConfirmModal
            show={showDeleteModal}
            handleClose={() => {
                setShowDeleteModal(false)
                setDeleteData({})
            }}
            onConfirm={() => {
                deleteCampaignMutate(deleteData.id)
                setShowDeleteModal(false)
                setDeleteData({})
            }}
            isConfirmLoading={deleteCampaignIsLoading}
            name={'Campaign ' + formatDate(deleteData?.start_date, false) + ' - ' + formatDate(deleteData?.end_date, false)}
        />
    </>
}

export default Authorization(TunjanganHariKarya, ['admin', 'officer'])