import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/courses/schedules'


export function getSchedules({ courseId = null, userId = null, name = null, startAt = null, status = null, haveScheduleCode = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/`, {
        params: {
            course_id: courseId,
            mentor_user_id: userId,
            name,
            start_at: startAt,
            status,
            have_schedule_code: haveScheduleCode ? 1 : 0,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function getSchedule(scheduleId) {
    return axios.get(`${API_URL}/${scheduleId}`)
}

export function getScheduleStatistic(scheduleId) {
    return axios.get(`${API_URL}/${scheduleId}/statistic`)
}

export function getScheduleSessions({ scheduleId, status = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/${scheduleId}/sessions`, {
        params: {
            status,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function getScheduleSession(scheduleId, sessionId) {
    return axios.get(`${API_URL}/${scheduleId}/sessions/${sessionId}`)
}

export function getScheduleSessionParticipants({ scheduleId, sessionId, keyword = null, status = null, verified_status = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/${scheduleId}/sessions/${sessionId}/participants`, {
        params: {
            keyword,
            status,
            verified_status,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function updateScheduleSessionParticipant({ scheduleId, sessionId, userId, status }) {
    return axios.post(`${API_URL}/${scheduleId}/sessions/${sessionId}/participants/${userId}`, { status }, {
        params: {
            _method: 'PUT'
        }
    })
}

export function getScheduleStudents({ scheduleId, redeemStatus = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/${scheduleId}/students`, {
        params: {
            redeem_status: redeemStatus,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function getScheduleStudent(scheduleId, studentId) {
    return axios.get(`${API_URL}/${scheduleId}/students/${studentId}`)
}

export function getScheduleStudentLessonProgress(scheduleId, studentId) {
    return axios.get(`${API_URL}/${scheduleId}/students/${studentId}/lesson_progress`)
}

export function updateScheduleStudentSkillTestScore({ scheduleId, userId, skillTestScore }) {
    return axios.post(`${API_URL}/${scheduleId}/students/${userId}/skill_test_score`, { skill_test_score: skillTestScore }, {
        params: {
            _method: 'PUT'
        }
    })
}

export function createZoomMeeting(scheduleId, sessionId, zoomUserId) {
    return axios.post(`${API_URL}/${scheduleId}/sessions/${sessionId}/zoom-meeting`, {
        zoom_user_id: zoomUserId
    })
}

export function getScheduleStudentsProgress({ scheduleId }) {
    return axios.get(`${API_URL}/${scheduleId}/students/progress`)
}

export function generateZoomLogs(scheduleId, sessionId) {
    return axios.post(`${API_URL}/${scheduleId}/sessions/${sessionId}/zoom-logs`)
}

export function getZoomLogs(scheduleId, sessionId, registrantId) {
    return axios.get(`${API_URL}/${scheduleId}/sessions/${sessionId}/zoom-logs`, {
        params: {
            registrant_id: registrantId
        }
    })
}

export function compareSiva(scheduleId, data) {
    return axios.post(`${API_URL}/${scheduleId}/students/compare-siva`, data)
}

export function getUnverifiedStudents(scheduleId, sessionId) {
    return axios.get(`${API_URL}/${scheduleId}/sessions/${sessionId}/unverified-students`)
}

export function getNotJoinedStudents(scheduleId, sessionId) {
    return axios.get(`${API_URL}/${scheduleId}/sessions/${sessionId}/not-joined-students`)
}

export function getMeetingParticipants(scheduleId, sessionId) {
    return axios.get(`${API_URL}/${scheduleId}/sessions/${sessionId}/meeting-participants`)
}

export function getRealtimeActivity(scheduleId, sessionId) {
    return axios.get(`${API_URL}/${scheduleId}/sessions/${sessionId}/realtime-activity`)
}