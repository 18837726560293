import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/campaign/tunjangan-hari-karya'

export function getCampaigns({ status = null, formStatus = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(API_URL, {
        params: {
            status,
            form_status: formStatus,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function getCampaign(campaignId) {
    return axios.get(`${API_URL}/${campaignId}`)
}

export function createCampaign(data) {
    return axios.post(API_URL, data)
}

export function updateCampaign(campaignId, data) {
    return axios.put(`${API_URL}/${campaignId}`, data)
}

export function deleteCampaign(campaignId) {
    return axios.delete(`${API_URL}/${campaignId}`)
}

export function getCampaignStatistics() {
    return axios.get(`${API_URL}/statistic`)
}

export function updateSettings(data) {
    return axios.put(`${API_URL}/settings`, data)
}

export function getObjectives() {
    return axios.get(`${API_URL}/objectives`)
}

export function getApplicants({ campaignId, fullName = null, gender = null, phoneNo = null, digitalPlatforms = null, status = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/${campaignId}/applicants`, {
        params: {
            full_name: fullName,
            gender,
            phone_no: phoneNo,
            digital_platforms: digitalPlatforms ? [digitalPlatforms] : null,
            status,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function getApplicant(campaignId, userId) {
    return axios.get(`${API_URL}/${campaignId}/applicants/${userId}`)
}

export function updateApplicant(campaignId, userId, data) {
    return axios.put(`${API_URL}/${campaignId}/applicants/${userId}`, data)
}

export function getApplicantsStatistic(campaignId) {
    return axios.get(`${API_URL}/${campaignId}/applicants/statistic`)
}

export function getWithdrawals({ campaignId, fullName = null, ewallet = null, ewalletAddress = null, status = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/${campaignId}/withdrawals`, {
        params: {
            full_name: fullName,
            ewallet,
            ewallet_address: ewalletAddress,
            status,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function getWithdraw(campaignId, userId) {
    return axios.get(`${API_URL}/${campaignId}/withdrawals/${userId}`)
}

export function updateWithdraw(campaignId, userId, data) {
    return axios.put(`${API_URL}/${campaignId}/withdrawals/${userId}`, data)
}

export function getCampaignStatistic(campaignId) {
    return axios.get(`${API_URL}/${campaignId}/statistic`)
}