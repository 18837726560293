import { Field, FieldArray, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"

import Spinner from "../../../../../components/Spinner/Spinner"


const schema = Yup.object().shape({
    title: Yup.string().required().min(5).max(255).label('Title'),
    questions: Yup.array().of(
        Yup.object().shape({
            question: Yup.string().required().label('Question'),
            answer: Yup.string().required().label('Answer')
        })
    ).required().min(2).label('Questions')
})

const LessonTypePopupQuizDragTheWords = ({ lesson, onSubmit, isSubmitLoading }) => {
    const formik = useFormik({
        initialValues: {
            title: lesson ? lesson.title : '',
            questions: lesson?.popup_quiz_attributes ? lesson.popup_quiz_attributes : []
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            data.type = 'popup_quiz_drag_the_words'

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-4">
                <label className="form-label required">Title</label>
                <input type="text" name="title" className="form-control" placeholder="Lesson Title" onChange={formik.handleChange} value={formik.values.title} />
                {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
            </div>
            <FormikProvider value={formik}>
                <FieldArray name="questions">
                    {({ push, remove }) => (
                        <>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <p className="form-label required mb-0">Questions</p>
                                <button type="button" className="btn btn-sm btn-light-primary" onClick={() => push()}>Add Question</button>
                            </div>
                            <div className="mb-8">
                                {formik.values.questions?.length > 0 && formik.values.questions.map((_, i) => (
                                    <div key={i}>
                                        {i > 0 && <div className="separator my-3" />}
                                        <div className="d-flex align-items-center mb-3">
                                            <div className="w-100 me-3">
                                                <Field name={`questions.${i}.question`} className="form-control" placeholder="Question" />
                                                {formik.errors.questions && Array.isArray(formik.errors.questions) ? <small className="form-text text-danger">{formik.errors.questions[i]?.question}</small> : null}
                                                <Field name={`questions.${i}.answer`} className="form-control mt-1" placeholder="Answer" />
                                                {formik.errors.questions && Array.isArray(formik.errors.questions) ? <small className="form-text text-danger">{formik.errors.questions[i]?.answer}</small> : null}
                                            </div>
                                            <div className="flex-shrink-1">
                                                <button type="button" className="btn btn-sm btn-light-danger" onClick={() => remove(i)}><i className="fa fa-trash p-0"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {formik.errors.questions && !Array.isArray(formik.errors.questions) ? <small className="form-text text-danger">{formik.errors.questions}</small> : null}
                            </div>
                        </>
                    )}
                </FieldArray>
            </FormikProvider>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || isSubmitLoading}>
                {!lesson ? 'Create' : 'Edit'} Lesson {isSubmitLoading ? <Spinner size="sm" /> : null}
            </button>
        </form>
    )
}

export default LessonTypePopupQuizDragTheWords