import { Link, Outlet, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { toast } from "react-toastify"

import { getScheduleSession } from "../../../../../../models/courses/schedules"
import PageSpinner from "../../../../../../components/Spinner/PageSpinner"
import PageError from "../../../../../../components/Error/PageError"
import { Helmet } from "react-helmet"
import { checkIsActive } from "../../../../../../../_metronic/helpers"
import clsx from "clsx"
import { eventDate, formatDate } from "../../../../../../helpers/common"

const MenuListItem = ({ to, children }) => {
    const { pathname } = useLocation()

    return (
        <li className="nav-item mt-2">
            <Link
                className={clsx("nav-link text-active-primary ms-0 me-10 py-5", {
                    "active": checkIsActive(pathname, to)
                })}
                to={to}
            >
                {children}
            </Link>
        </li>
    )
}

export default function Session() {
    const { scheduleId, sessionId } = useParams()
    const { schedule } = useOutletContext()
    const navigate = useNavigate()

    const { isLoading, isError, error, data: { data: session } = {} } = useQuery(`schedule-${scheduleId}-session-${sessionId}`, () => getScheduleSession(scheduleId, sessionId), {
        retry: 0,
        onError: error => {
            if (error.response.status === 404) {
                toast.error('Session not found')
                navigate(`/courses/schedules/${scheduleId}/sessions`)
            }
        }
    })

    return (
        <>
            <Helmet>
                <title>Session {!isLoading ? ' - ' + session?.lesson?.title + ' ' : ''} - {schedule.schedule_code} - {schedule.course?.title}</title>
            </Helmet>
            <div className="min-h-500px">
                {isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : (
                    <>
                        <div className="card mb-6">
                            <div className="card-body p-6 pb-0">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p className="mb-1 text-primary">{session.lesson?.section?.title}</p>
                                        <h3 className="mb-2">{session.lesson?.title}</h3>
                                        <p className="text-muted mb-0">{formatDate(session.start_datetime)}</p>
                                    </div>
                                    <div>
                                        {session.status === 'inactive' ? <span className="badge badge-square badge-lg badge-light p-3">Upcoming</span> : null}
                                        {session.status === 'active' ? <span className="badge badge-square badge-lg badge-light-primary p-3">On Going</span> : null}
                                        {session.status === 'finished' ? <span className="badge badge-square badge-lg badge-light-primary p-3">Finished</span> : null}
                                    </div>
                                </div>
                                {/* <div className="separator my-6"></div>
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <p className="fw-bold fs-6 text-muted mb-1">Date Time</p>
                                        <p class="fs-6 mb-0">{eventDate(session.start_datetime)}</p>
                                    </div>
                                    <div className="col-12 col-md-2 mt-4 mt-md-0">
                                        <p className="fw-bold fs-6 text-muted mb-1">Duration</p>
                                        <p class="fs-6 mb-0">{durationToText(session.lesson?.duration)}</p>
                                    </div>
                                    <div className="col-12 col-md-2 mt-4 mt-md-0">
                                        <p className="fw-bold fs-6 text-muted mb-1">Absent Code</p>
                                        <p class="fs-6 mb-0">{session.absent_code}</p>
                                    </div>
                                    <div className="col-12 col-md-4 mt-4 mt-md-0">
                                        <p className="fw-bold fs-6 text-muted mb-1">Host</p>
                                        <p class="fs-6 mb-0">{schedule?.zoom_user?.email || '-'}</p>
                                    </div>
                                    <div className="col-12 col-md-4 mt-4">
                                        <p className="fw-bold fs-6 text-muted mb-1">Participant Count</p>
                                        <p class="fs-6 mb-0"><span className="fw-semibold">{session.total_participants}</span> of <span className="fw-semibold">{schedule.total_students}</span></p>
                                    </div>
                                    <div className="col-12 col-md-8 mt-4">
                                        <p className="fw-bold fs-6 text-muted mb-1">Meeting URL</p>
                                        <p class="fs-6 mb-0">
                                            {session.meeting_url ? <a href={session.meeting_url || '#'} target="_blank" rel="noreferrer">{session.meeting_url}</a> : '-'}
                                        </p>
                                    </div>
                                </div> */}
                                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                                    <MenuListItem
                                        to={`/courses/schedules/${schedule.id}/sessions/${session.id}/overview`}
                                    >
                                        Overview
                                    </MenuListItem>
                                    <MenuListItem
                                        to={`/courses/schedules/${schedule.id}/sessions/${session.id}/absent`}
                                    >
                                        Absent
                                    </MenuListItem>
                                </ul>
                            </div>
                        </div>

                        <Outlet context={{ schedule, session }} />
                    </>
                )}
            </div>
        </>
    )
}
