import { Field, FieldArray, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"

import Spinner from "../../../../../components/Spinner/Spinner"


const schema = Yup.object().shape({
    title: Yup.string().required().min(5).max(255).label('Title'),
    questions: Yup.array().of(
        Yup.object().shape({
            question: Yup.string().required().nullable().label('Question'),
            answers: Yup.array().of(
                Yup.string().required().label('Answer')
            ).min(2).label('Answers'),
            correct_answer: Yup.number().required().nullable().label('Correct Answer')
        })
    ).required().min(1).label('Questions')
})

const LessonTypePopupQuizProblemSolving = ({ lesson, onSubmit, isSubmitLoading }) => {
    const formik = useFormik({
        initialValues: {
            title: lesson ? lesson.title : '',
            questions: lesson?.popup_quiz_attributes ? lesson.popup_quiz_attributes : []
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            data.type = 'popup_quiz_problem_solving'

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
                <div className="form-group mb-4">
                    <label className="form-label required">Title</label>
                    <input type="text" name="title" className="form-control" placeholder="Lesson Title" onChange={formik.handleChange} value={formik.values.title} />
                    {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
                </div>
                <FieldArray name="questions">
                    {({ push, remove }) => (
                        <>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <p className="form-label required mb-0">Questions</p>
                                <button type="button" className="btn btn-sm btn-light-primary" onClick={() => push({
                                    question: null,
                                    answers: [],
                                    correct_answer: null
                                })}>Add Question</button>
                            </div>
                            <div className="mb-8">
                                {formik.values.questions?.length > 0 && formik.values.questions.map((question, i) => (
                                    <div key={i} className="mb-6 p-3 border rounded">
                                        <span className="badge badge-square badge-light-primary p-3 text-capitalize">Question {i + 1}</span>
                                        <div className="d-flex align-items-center gap-3 mt-3">
                                            <Field name={`questions.${i}.question`} className="form-control" placeholder="Question" />
                                            <button type="button" className="btn btn-sm btn-danger" onClick={() => remove(i)}><i className="fa fa-trash p-0"></i></button>
                                        </div>
                                        {formik.errors.questions && Array.isArray(formik.errors.questions) ? <small className="form-text text-danger">{formik.errors.questions[i]?.question}</small> : null}
                                        <div className="separator mt-3"></div>
                                        <FieldArray name={`questions.${i}.answers`}>
                                            {({ push: pushAnswer, remove: removeAnswer }) => (
                                                <>
                                                    {formik.values.questions[i]?.answers?.length > 0 && formik.values.questions[i]?.answers?.map((_, ii) => (
                                                        <div key={ii}>
                                                            <div className="d-flex align-items-center gap-3 mt-3">
                                                                <div className="form-check form-check-custom form-check-solid">
                                                                    <input type="radio" name={`questions.${i}.correct_answer`} className="form-check-input" onChange={e => formik.setFieldValue(`questions.${i}.correct_answer`, ii)} value={ii} checked={question.correct_answer === ii} />
                                                                </div>
                                                                <Field name={`questions.${i}.answers.${ii}`} className="form-control" placeholder="Answer" />
                                                                <button type="button" className="btn btn-sm btn-light-danger" onClick={() => removeAnswer(ii)}><i className="fa fa-trash p-0"></i></button>
                                                            </div>
                                                            {formik.errors.questions && Array.isArray(formik.errors.questions) && formik.errors.questions[i]?.answers && Array.isArray(formik.errors.questions[i].answers) ? <small className="d-block form-text text-danger">{formik.errors.questions[i].answers[ii]}</small> : null}
                                                        </div>
                                                    ))}
                                                    {formik.errors.questions && Array.isArray(formik.errors.questions) ? <small className="d-block form-text text-danger">{formik.errors.questions[i]?.correct_answer}</small> : null}
                                                    {formik.errors.questions && Array.isArray(formik.errors.questions) && formik.errors.questions[i]?.answers && !Array.isArray(formik.errors.questions[i].answers) ? <small className="d-block mt-3 form-text text-danger">{formik.errors.questions[i].answers}</small> : null}
                                                    <button type="button" className="btn btn-sm btn-light-primary w-100 mt-3" onClick={() => pushAnswer()}>Add Answer</button>
                                                </>
                                            )}
                                        </FieldArray>
                                    </div>
                                ))}
                                {formik.errors.questions && !Array.isArray(formik.errors.questions) ? <small className="form-text text-danger">{formik.errors.questions}</small> : null}
                            </div>
                        </>
                    )}
                </FieldArray>
            </FormikProvider>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || isSubmitLoading}>
                {!lesson ? 'Create' : 'Edit'} Lesson {isSubmitLoading ? <Spinner size="sm" /> : null}
            </button>
        </form>
    )
}

export default LessonTypePopupQuizProblemSolving