import { FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import ReactQuill from "react-quill"
import ReactDatePicker from "react-datepicker"
import moment from "moment"
import 'react-quill/dist/quill.snow.css'
import "react-datepicker/dist/react-datepicker.css"

import Spinner from "../../../../components/Spinner/Spinner"


const schema = Yup.object().shape({
    quota: Yup.number().required().min(0).label('Quota'),
    date_range: Yup.array().of(Yup.date().required().nullable().label('Date Range')).required().label('Date Range')
})

const CampaignForm = ({ campaign = null, onSubmit, isSubmitLoading }) => {
    const formik = useFormik({
        initialValues: {
            date_range: campaign?.start_date ? [new Date(campaign.start_date), new Date(campaign.end_date)] : [null, null],
            description: campaign?.description ? campaign.description : undefined,
            quota: campaign?.quota ? campaign.quota : 0,
            status: campaign?.status ? campaign.status : 'inactive',
            form_status: campaign?.form_status ? campaign.form_status : 'inactive',
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            delete data.date_range

            data.start_date = moment(values.date_range[0]).format('YYYY-MM-DD')
            data.end_date = moment(values.date_range[1]).format('YYYY-MM-DD')

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
                <div className="form-group mb-4">
                    <label className="form-label required">Date Range</label>
                    <ReactDatePicker
                        name="date_range"
                        className="form-control"
                        selectsRange
                        placeholderText="Date Range"
                        startDate={formik.values.date_range[0]}
                        endDate={formik.values.date_range[1]}
                        onChange={date => formik.setFieldValue('date_range', date)}
                        autoComplete="off"
                    />
                    {formik.errors.date_range && <small className="text-danger">{formik.errors.date_range}</small>}
                </div>
                <div className="form-group mb-4">
                    <label className="form-label required">Description</label>
                    <ReactQuill theme='snow' name="description" value={formik.values.description} onChange={value => formik.setFieldValue('description', value)} />
                    {formik.errors.description && <small className="text-danger">{formik.errors.description}</small>}
                </div>
                <div className="form-group mb-4">
                    <label className="form-label required">Quota</label>
                    <input type="number" name="quota" className="form-control" placeholder="Quota" min={0} onChange={formik.handleChange} value={formik.values.quota} />
                    {formik.errors.quota && <small className="text-danger">{formik.errors.quota}</small>}
                </div>
                <div className="form-group mb-4">
                    <label className="form-label required">Status</label>
                    <select className="form-select" name="status" onChange={formik.handleChange} value={formik.values.status}>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                    {formik.errors.status && <small className="text-danger">{formik.errors.status}</small>}
                </div>
                <div className="form-group mb-8">
                    <label className="form-label required">Form Status</label>
                    <select className="form-select" name="form_status" onChange={formik.handleChange} value={formik.values.form_status}>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                    {formik.errors.form_status && <small className="text-danger">{formik.errors.form_status}</small>}
                </div>
                <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || isSubmitLoading}>
                    {campaign ? 'Update' : 'Create'} Campaign {isSubmitLoading && <Spinner size="sm" className="ms-1" />}
                </button>
            </FormikProvider>
        </form>
    )
}

export default CampaignForm