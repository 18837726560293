import { PageTitle } from "../../../_metronic/layout/core"
import { useAuth } from "../../modules/auth"

import MonthlyUsersCard from "./components/MonthlyUsersCard/MonthlyUsersCard"
import MonthlySalesCard from "./components/MonthlySalesCard/MonthlySalesCard"
import YearlySalesCard from "./components/YearlySalesCard/YearlySalesCard"
import TotalCourse from "./components/TotalCourse"
import TotalUser from "./components/TotalUser"
import TotalEnrol from "./components/TotalEnrol"
import TotalSales from "./components/TotalSales"
import TotalRedemption from "./components/TotalRedemption"
import TotalCompletion from "./components/TotalCompletion"


const Dashboard = () => {
    const { currentUser: { role_id: roleId } } = useAuth()

    return (
        <div className="min-h-500px">
            <PageTitle>Dashboard</PageTitle>
            <div className="row">
                <div className="col-6 col-md-3 mb-4 mb-md-0">
                    <TotalCourse />
                </div>
                {['admin'].includes(roleId) ? (
                    <>
                    <div className="col-6 col-md-3 mb-4 mb-md-0">
                        <TotalUser />
                    </div>
                    <div className="col-6 col-md-3 mb-4 mb-md-0">
                        <TotalEnrol />
                    </div>
                    <div className="col-6 col-md-3">
                        <TotalRedemption />
                    </div>
                    <div className="col-6 col-md-3 mt-0 mt-md-4">
                        <TotalCompletion />
                    </div>
                    {/* <div className="col-6 col-md-3 mb-4 mb-md-0">
                        <TotalSales />
                    </div> */}
                    </>
                ) : null}
            </div>
            {['admin'].includes(roleId) ? (
                <>
                    <div className="row mt-6">
                        <div className="col-12 col-md-6">
                            <MonthlyUsersCard />
                        </div>
                        {/* <div className="col-12 col-md-6">
                            <MonthlySalesCard />
                        </div> */}
                    </div>
                    {/* <div className="row mt-6">
                        <div className="col-12">
                            <YearlySalesCard />
                        </div>
                    </div> */}
                </>
            ) : null}
        </div>
    )
}

export default Dashboard