import { Modal } from "react-bootstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { toast } from "react-toastify"
import { toRupiah } from "../../../../../helpers/common"
import { useState } from "react"
import { useMutation } from "react-query"
import { updateWithdraw } from "../../../../../models/tunjangan-hari-karya"
import Spinner from "../../../../../components/Spinner/Spinner"


const WithdrawDetailModal = ({ show, onHide, queryClient, queryKey, campaign, withdraw }) => {
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [confirmationMessage, setConfirmationMessage] = useState(null)

    const { isLoading, mutate } = useMutation(data => updateWithdraw(campaign.id, withdraw.user_id, data), {
        onSuccess: () => {
            toast.success('Applicant Updated')
            queryClient.invalidateQueries([queryKey])
            hideModal()
        },
        onError: () => toast.error('Failed to update applicant')
    })

    const hideModal = () => {
        setConfirmationMessage(null)
        setShowConfirmation(false)
        onHide()
    }
    
    return (
        <Modal show={show} onHide={() => hideModal()} centered>
            <Modal.Header closeButton>
                <Modal.Title className="d-flex gap-3">{withdraw.full_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h1 className="text-center">{toRupiah(withdraw.value)} <CopyToClipboard text={withdraw.value} onCopy={() => toast.success('Copied!')}><i className="fa fa-clone cursor-pointer"></i></CopyToClipboard></h1>
                <p className="text-center text-muted">to</p>
                <h3 className="text-center mb-0">{withdraw.ewallet_address} ({withdraw.ewallet_desc}) <CopyToClipboard text={withdraw.ewallet_address} onCopy={() => toast.success('Copied!')}><i className="fa fa-clone cursor-pointer"></i></CopyToClipboard></h3>
                {withdraw.withdraw_status == 'failed' && (
                    <>
                        <hr className="mt-8" />
                        <p className="fw-bold mb-0">Message</p>
                        <p className="mb-0">{withdraw.message}</p>
                    </>
                )}
            </Modal.Body>
            {withdraw.withdraw_status === 'pending' && (
                <Modal.Footer className="d-block">
                    {showConfirmation ?
                        showConfirmation === 'failed' ? (
                            <div>
                                <div className="form-group">
                                    <label className="form-label required text-start">Message</label>
                                    <input type="text" className="form-control" placeholder="Message..." onChange={(e) => setConfirmationMessage(e.target.value)} value={confirmationMessage} />
                                </div>
                                <div className="d-flex justify-content-center gap-3 mt-6">
                                    <button type="button" className="btn btn-danger" disabled={!confirmationMessage || isLoading} onClick={() => mutate({ withdraw_status: 'failed', message: confirmationMessage })}>
                                        {isLoading && <Spinner size="sm" className="mr-1" />} Mark as Failed
                                    </button>
                                    <button type="button" className="btn btn-light" disabled={isLoading} onClick={() => {
                                        setConfirmationMessage(null)
                                        setShowConfirmation(false)
                                    }}>Cancel</button>
                                </div>
                            </div>
                        ) : showConfirmation === 'success' ? (
                            <div>
                                <h4 className="text-center">Are you sure?</h4>
                                <div className="d-flex justify-content-center gap-3 mt-6">
                                    <button type="button" className="btn btn-success" disabled={isLoading} onClick={() => mutate({ withdraw_status: 'success' })}>
                                        {isLoading && <Spinner size="sm" className="mr-1" />} Yes
                                    </button>
                                    <button type="button" className="btn btn-light" disabled={isLoading} onClick={() => {
                                        setConfirmationMessage(null)
                                        setShowConfirmation(false)
                                    }}>Cancel</button>
                                </div>
                            </div>
                        ) : null
                    : (
                        <div className="d-flex justify-content-center gap-3">
                            <button type="button" className="btn btn-success" onClick={() => setShowConfirmation('success')}>Mark as Success</button>
                            <button type="button" className="btn btn-danger" onClick={() => setShowConfirmation('failed')}>Mark as Failed</button>
                        </div>
                    )}
                </Modal.Footer>
            )}
        </Modal>
    )
}

export default WithdrawDetailModal