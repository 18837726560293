import DateTimeCell from "../../../../../../components/Table/cells/DateTime"
import NumberCell from "../../../../../../components/Table/cells/Number"
import StatusCell from "./StatusCell"

const applicantColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        className: 'ps-2',
        Cell: ({ ...props }) => <NumberCell {...props} />
    }, {
        Header: 'Full Name',
        accessor: 'full_name',
    }, {
        Header: 'Phone No',
        accessor: 'phone_no',
    }, {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ ...props }) => <StatusCell status={props.data[props.row.index].status} withdrawStatus={props.data[props.row.index].withdraw_status} />
    }, {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: ({ ...props }) => <DateTimeCell dateTime={props.data[props.row.index].created_at} />
    }, {
        Header: 'Last Update',
        accessor: 'updated_at',
        Cell: ({ ...props }) => <DateTimeCell dateTime={props.data[props.row.index].updated_at} />
    },
]

const columnAliases = {
    number: 'id'
}

export { applicantColumns, columnAliases }