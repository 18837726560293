const StatusCell = ({ status }) => {
    if (!status) return '-'

    const statuses = {
        pending: {
            className: 'badge-secondary',
            name: 'Pending'
        }, success: {
            className: 'badge-success',
            name: 'Success'
        }, failed: {
            className: 'badge-light-danger',
            name: 'Failed'
        }
    }

    return (
        <div className="d-flex gap-2">
            <span className={"text-capitalize badge " + statuses[status].className}>{statuses[status].name}</span>
        </div>
    )
}

export default StatusCell