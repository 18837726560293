import { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"

import { useAuth } from "../../modules/auth"
import { getProfile, login } from "../../modules/auth/core/_requests"
import { toAbsoluteUrl } from "../../../_metronic/helpers"
import Spinner from "../../components/Spinner/Spinner"


const schema = Yup.object().shape({
    email: Yup.string().email().required().label('Email'),
    password: Yup.string().required().label('Password')
})

const Login = () => {
    const [isLoading, setIsLoading] = useState(false)
    const { saveAuth, setCurrentUser } = useAuth()

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember: ''
        },
        validationSchema: schema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setIsLoading(true)
            try {
                const { data: auth } = await login(values.email, values.password, values.remember)
                saveAuth(auth)
                const { data: user } = await getProfile(auth.access_token)
                setCurrentUser(user)
            } catch (error) {                
                saveAuth(undefined)
                setSubmitting(false)
                setIsLoading(false)

                if (error.response?.status === 403) {
                    setStatus('Akses ditolak. Peserta silahkan masuk melalui halaman https://bebasjamkerja.com')
                    if (error.response?.data?.data?.redirect_url) window.location.replace(error.response?.data?.data?.redirect_url)
                } else setStatus('The login details are incorrect')
            }
        },
    })

    return (
        <div className="d-flex flex-column align-items-center justify-content-center vh-100 bg-white bg-md-light">
            <div className="card w-100 w-md-25 shadow-none">
                <div className="card-body shadow-none">
                    <div className="mb-8 text-center">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/logos/default.png')}
                            className='h-50px'
                        />
                    </div>
                    <form onSubmit={formik.handleSubmit} className="mb-4">
                        {formik.status ? (
                            <div className="alert bg-danger text-white" role="alert">
                                {formik.status}
                            </div>
                        ) : null}
                        <div className="form-group mb-4">
                            <input type="email" name="email" className="form-control form-control-solid" placeholder="Email" value={formik.values.email} onChange={formik.handleChange} />
                            {formik.errors.email ? <small className="form-text text-danger">{formik.errors.email}</small> : null}
                        </div>
                        <div className="form-group mb-4">
                            <input type="password" name="password" className="form-control form-control-solid" placeholder="Password" value={formik.values.password} onChange={formik.handleChange} />
                            {formik.errors.password ? <small className="form-text text-danger">{formik.errors.password}</small> : null}
                        </div>
                        <div className="form-check form-check-custom form-check-solid mb-8">
                            <input type="checkbox" name="remember" className="form-check-input" id="remember-me" onChange={e => formik.setFieldValue('remember', e.target.checked)} />
                            <label className="form-check-label" for="remember-me">
                                Remember me
                            </label>
                        </div>
                        <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || isLoading}>
                            Sign In {isLoading ? <Spinner size="sm" color="white" className="ms-2" /> : null}
                        </button>
                    </form>
                    <p className="text-center mb-0">
                        <a href="https://bebasjamkerja.com">Back to Main Website</a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Login