import { useFormik } from "formik"
import * as Yup from "yup"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

import Spinner from "../../../../../components/Spinner/Spinner"


const LessonTypeVideo = ({ lesson, onSubmit, isSubmitLoading }) => {
    const schema = Yup.object().shape({
        title: Yup.string().required('Title is required').min(5, 'Title must be at least 5 characters').max(255, 'Title must be at most 255 characters'),
        video: !lesson ? Yup.mixed().when('source', {
            is: 'system',
            then: Yup.mixed().test('required', 'Video is required', file => {
                if (file) return true
                return false
            })
        }) : null,
        video_url: Yup.string().when('source', {
            is: 'youtube',
            then: Yup.string().url().required().label('Video URL')
        }),
        duration: Yup.number().when('source', {
            is: 'youtube',
            then: Yup.number().required().min(0).label('Duration')
        })
    })

    const formik = useFormik({
        initialValues: {
            title: lesson ? lesson.title : '',
            source: lesson ? lesson.video_source : 'system',
            video: undefined,
            video_url: lesson ? lesson.video_source === 'youtube' ? lesson.content_url : '' : '',
            duration: lesson ? lesson.video_source === 'youtube' ? lesson.duration : '' : '',
            summary: lesson ? lesson.summary : ''
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = new FormData()
            data.append('type', 'video')
            data.append('title', values.title)
            data.append('source', values.source)
            if (values.video) data.append('video', values.video)
            if (values.video_url) data.append('video_url', values.video_url)
            if (values.duration !== null) data.append('duration', values.duration)
            data.append('summary', values.summary)

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-4">
                <label className="form-label required">Title</label>
                <input type="text" name="title" className="form-control" placeholder="Lesson Title" onChange={formik.handleChange} value={formik.values.title} />
                {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label required">Source</label>
                <select name="source" className="form-select" value={formik.values.source} onChange={formik.handleChange}>
                    <option value="system">System</option>
                    <option value="youtube">Youtube</option>
                </select>
                {formik.errors.source ? <small className="form-text text-danger">{formik.errors.source}</small> : null}
            </div>
            {formik.values.source === 'youtube' ? (
                <>
                    <div className="form-group mb-4">
                        <label className="form-label required">Youtube URL</label>
                        <input type="text" name="video_url" className="form-control" value={formik.values.video_url} onChange={formik.handleChange} placeholder="https://youtu.be/u_4qbHCV1ks" />
                        {formik.errors.video_url ? <small className="form-text text-danger">{formik.errors.video_url}</small> : null}
                    </div>
                    <div className="form-group mb-4">
                        <label className="form-label required">Duration</label>
                        <input type="number" name="duration" className="form-control" value={formik.values.duration} onChange={formik.handleChange} />
                        {formik.errors.duration ? <small className="form-text text-danger">{formik.errors.duration}</small> : null}
                    </div>
                </>
            ) : (
                <div className="form-group mb-4">
                    <label className={"form-label " + (!lesson ? 'required' : '')}>Video</label>
                    <input type="file" name="video" className="form-control" onChange={e => formik.setFieldValue('video', e.currentTarget.files[0])} />
                    {formik.errors.video ? <small className="form-text text-danger">{formik.errors.video}</small> : null}
                </div>
            )}
            <div className="form-group mb-8">
                <label className="form-label">Summary</label>
                <ReactQuill theme='snow' name="summary" value={formik.values.summary} onChange={value => formik.setFieldValue('summary', value)} />
                {formik.errors.summary ? <small className="form-text text-danger">{formik.errors.summary}</small> : null}
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || isSubmitLoading}>
                {!lesson ? 'Create' : 'Edit'} Lesson {isSubmitLoading ? <Spinner size="sm" /> : null}
            </button>
        </form>
    )
}

export default LessonTypeVideo