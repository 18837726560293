import { useFormik } from "formik"
import { Modal } from "react-bootstrap"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import * as Yup from "yup"
import Spinner from "../../../../components/Spinner/Spinner"
import clsx from "clsx"
import { formatDate } from "../../../../helpers/common"
import { taskFeedback } from "../../../../models/courses/tasks"


const schema = Yup.object().shape({
    score: Yup.number().required().min(0).max(100).label('Score'),
    notes: Yup.string().nullable().required().label('Notes')
})

const TaskDetailForm = ({ data, hasNotesFile = false, onSubmit, submitLoading }) => {
    const formik = useFormik({
        initialValues: {
            score: data?.score,
            notes: data?.notes,
            notes_file: null,
            remove_notes_file: 'no'
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = new FormData()

            data.append('score', values.score)
            data.append('notes', values.notes)
            data.append('remove_notes_file', values.remove_notes_file)
            if (values.notes_file) data.append('notes_file', values.notes_file)

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-3">
                <label htmlFor="score" className="fw-semibold required mb-2">Score</label>
                <input type="number" name="score" className="form-control" min={0} max={100} onChange={formik.handleChange} value={formik.values.score} />
                {formik.errors.score ? <small className="form-text text-danger">{formik.errors.score}</small> : null}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="notes" className="fw-semibold required mb-2">Notes</label>
                <textarea name="notes" className="form-control" rows={8} onChange={formik.handleChange} value={formik.values.notes}></textarea>
                {formik.errors.notes ? <small className="form-text text-danger">{formik.errors.notes}</small> : null}
            </div>
            <div className="form-group">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <label htmlFor="notes-file" className="fw-semibold">File</label>
                    {hasNotesFile && !formik.values.notes_file && formik.values.remove_notes_file === 'no' && (
                        <div className="d-inline-flex align-items-center gap-2 cursor-pointer text-danger" onClick={() => formik.setFieldValue('remove_notes_file', 'yes')}>
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                    )}
                </div>
                <input type="file" name="notes_file" className="form-control" onChange={e => {
                    formik.setFieldValue('notes_file', e.currentTarget.files[0])
                    formik.setFieldValue('remove_notes_file', 'no');
                }} />
            </div>
            <button type="submit" className="btn btn-primary w-100 mt-8" disabled={!formik.isValid || submitLoading}>
                Save {submitLoading && <Spinner size="sm" />}
            </button>
        </form>
    )
}

const TaskDetailItem = ({ className, title, children }) => (
    <div className={clsx("mb-4 col-12", className)}>
        <p className="mb-0 fw-bold fs-6">{title}</p>
        {children}
    </div>
)

const TaskDetailModal = ({ show, onHide, queryKey, task }) => {
    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(data => taskFeedback(task.id, data), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([queryKey])
            onHide()
        },
        onError: error => {
            toast.error(error?.response?.data?.message)
        }
    })

    return (
        <Modal show={show} onHide={onHide} size="xl" backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Task Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {task?.id && (
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <p className="fs-1 mb-0">{task.name}</p>
                            <p className="text-muted">{task.email}</p>
                            <div className="separator separator-dashed my-6"></div>
                            <div className="row">
                                <TaskDetailItem title="Course">
                                    <p className="mb-0">{task.course_title}</p>
                                </TaskDetailItem>
                                <TaskDetailItem title="Section">
                                    <p className={clsx("mb-0", task.task_type === 'final' ? 'text-danger' : 'text-primary')}>{task.section_title}</p>
                                </TaskDetailItem>
                                <TaskDetailItem title="Last Update">
                                    <p className="mb-0">{formatDate(task.updated_at)}</p>
                                </TaskDetailItem>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 border-start border-start-dashed">
                            <p className="fw-bold fs-6">Files</p>
                            {task.files.map((file, i) => (
                                <div key={i} className="rounded p-4 border mb-2 d-flex align-items-center gap-4">
                                    <i className={clsx("fa fs-4 text-primary", file.file_type === 'image' ? 'fa-image' : file.file_type === 'video' ? 'fa-video' : 'fa-file')}></i>
                                    <div className="flex-grow-1 text-truncate">
                                        <p className="mb-0">{file.file_name}</p>
                                        <p className="mb-0 fs-8 text-muted"><i>{formatDate(file.created_at)}</i></p>
                                    </div>
                                    <div className="d-inline-flex align-items-center gap-2">
                                        <a href={file.file_url} className="btn btn-sm btn-light" target="_blank">
                                            <i className="fa fa-arrow-down p-0"></i>
                                        </a>
                                    </div>
                                </div>
                            ))}
                            {task.notes_file && (
                                <>
                                    <div className="separator separator-dashed my-6"></div>
                                    <p className="fw-bold fs-6">Feedback Files</p>
                                    <div className="rounded p-4 border mb-2 d-flex align-items-center gap-4">
                                        <i className="fa fa-file fs-4 text-success"></i>
                                        <div className="flex-grow-1 text-truncate">
                                            <p className="mb-0">Feedback File</p>
                                            <p className="mb-0 fs-8 text-muted"><i>{formatDate(task.file_updated_at || task.updated_at)}</i></p>
                                        </div>
                                        <div className="d-inline-flex align-items-center gap-2">
                                            <a href={task.notes_file_url} className="btn btn-sm btn-light" target="_blank">
                                                <i className="fa fa-arrow-down p-0"></i>
                                            </a>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="col-12 col-md-4 border-start border-start-dashed">
                            <TaskDetailForm
                                data={task}
                                hasNotesFile={!!task.notes_file}
                                onSubmit={data => mutate(data)}
                                submitLoading={isLoading}
                            />
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default TaskDetailModal