import { useEffect, useState } from "react"
import { PageTitle } from "../../../../../_metronic/layout/core"
import Authorization from "../../../../routing/Authorization"
import ApplicantFilter from "./components/ApplicantFilter"
import { useQuery, useQueryClient } from "react-query"
import { getApplicants } from "../../../../models/tunjangan-hari-karya"
import Table from "../../../../components/Table/Table"
import { applicantColumns, columnAliases } from "./components/ApplicantTable/_columns"
import GeneralPagination from "../../../../components/Pagination/GeneralPagination"
import ApplicantDetailModal from "./components/ApplicantDetailModal"
import { useOutletContext } from "react-router-dom"


const Applicants = () => {
    const { campaign } = useOutletContext()
    const queryKey = `tunjangan-hari-karya-${campaign.id}-applicants`

    const pageSize = 10
    const [showFilter, setShowFilter] = useState(true)
    const [filters, setFilters] = useState({
        status: 'pending'
    })
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({})
    const [showApplicantDetailModal, setShowApplicantDetailModal] = useState(false)
    const [applicantDetailData, setApplicantDetailData] = useState({})

    const queryClient = useQueryClient()
    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery([queryKey], () => getApplicants({
        campaignId: campaign.id,
        fullName: filters?.full_name,
        gender: filters?.gender,
        phoneNo: filters?.phone_no,
        digitalPlatforms: filters?.digital_platforms,
        status: filters?.status,
        page,
        rows: pageSize,
        sortBy
    }))

    useEffect(() => {
        queryClient.fetchQuery([queryKey])
    }, [queryClient, filters, page, sortBy])

    return (
        <>
            <div className="min-h-500px">
                <div className="card">
                    <div className="card-header p-6 d-block">
                        <div className="d-flex justify-content-between">
                            <button type="button" className={"btn btn-sm me-3 " + (showFilter ? 'btn-primary' : 'btn-light-primary')} onClick={() => setShowFilter(item => !item)}><i className="fa fa-filter"></i> Filter</button>
                        </div>
                        <ApplicantFilter
                            show={showFilter}
                            onSubmit={values => {
                                setPage(1)
                                setFilters(values)
                            }}
                        />
                    </div>
                    <div className="card-body p-6">
                        <Table
                            isClickableRow
                            columns={applicantColumns}
                            isLoading={isFetching}
                            isError={isError}
                            error={error}
                            data={!isLoading ? data.data : []}
                            currentPage={page}
                            pageSize={pageSize}
                            defaultSortBy={{
                                id: 'updated_at',
                                desc: true
                            }}
                            onSortByChange={sortBy => setSortBy(() => {
                                if (sortBy.length) return {
                                    id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                    direction: sortBy[0].desc ? 'DESC' : 'ASC'
                                }
                        
                                return {}
                            })}
                            onRowClick={row => {
                                setShowApplicantDetailModal(true)
                                setApplicantDetailData(row)
                            }}
                        />
                        
                        {isSuccess && <GeneralPagination
                            onPageChange={page => setPage(page)}
                            totalCount={data?.meta.total}
                            currentPage={page}
                            pageSize={pageSize}
                            from={data?.meta.from}
                            to={data?.meta.to}
                        />}
                    </div>
                </div>
            </div>

            <ApplicantDetailModal
                show={showApplicantDetailModal}
                onHide={() => {
                    setShowApplicantDetailModal(false)
                    setApplicantDetailData({})
                }}
                queryClient={queryClient}
                queryKey={queryKey}
                campaign={campaign}
                applicant={applicantDetailData}
            />
        </>
    )
}

export default Authorization(Applicants, ['admin', 'officer'])