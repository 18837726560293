import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

import Authorization from "../../../routing/Authorization"
import { PageTitle } from "../../../../_metronic/layout/core"
import PageError from "../../../components/Error/PageError"
import PageSpinner from "../../../components/Spinner/PageSpinner"
import DeleteConfirmModal from "../../../components/DeleteConfirmModal"
import SortModal from "../../../components/SortModal"
import { unSlug } from "../../../helpers/common"

import { getSections, deleteSection, updateSectionSort, updateLessonSort, deleteLesson, updateQuestionSort, deleteQuestion, updateRJQuestionSort, deleteRJQuestion, deleteLessonPopupQuiz, copyPreTestToPostTest } from "../../../models/courses/courses"
import CreateSectionModal from './components/CourseSectionModal/CreateSectionModal'
import EditSectionModal from './components/CourseSectionModal/EditSectionModal'
import CreateLessonModal from "./components/CourseLessonModal/CreateLessonModal"
import EditLessonModal from "./components/CourseLessonModal/EditLessonModal"
import CourseQuestionModal from "./components/CourseQuestionModal/CourseQuestionModal"
import CreateQuestionModal from "./components/CourseQuestionModal/CreateQuestionModal"
import EditQuestionModal from "./components/CourseQuestionModal/EditQuestionModal"
import LessonViewVideoModal from "./components/LessonViewVideoModal"
import LessonViewDocumentModal from "./components/LessonViewDocumentModal"
import CourseRJQuestionModal from "./components/CourseRJQuestionModal/CourseRJQuestionModal"
import CreateRJQuestionModal from "./components/CourseRJQuestionModal/CreateRJQuestionModal"
import EditRJQuestionModal from "./components/CourseRJQuestionModal/EditQuestionModal"
import LessonPopupQuizModal from "./components/LessonPopupQuizModal/LessonPopupQuizModal"
import CreatePopupQuizModal from "./components/LessonPopupQuizModal/CreatePopupQuizModal"
import EditPopupQuizModal from "./components/LessonPopupQuizModal/EditPopupQuizModal"


const breadcumbs = [
    {
        title: 'Courses',
        path: '/courses/manage',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const CourseCurriculum = () => {
    const { courseId } = useParams()

    let lessonCount = 1
    const sectionQueryKey = `course-${courseId}-sections`

    const [showSectionOrderModal, setShowSectionOrderModal] = useState(false)
    const [showCreateSectionModal, setShowCreateSectionModal] = useState(false)
    const [showEditSectionModal, setShowEditSectionModal] = useState(false)
    const [editSectionData, setEditSectionData] = useState({})
    const [showDeleteSectionModal, setShowDeleteSectionModal] = useState(false)
    const [deleteSectionData, setDeleteSectionData] = useState({})

    const [showLessonOrderModal, setShowLessonOrderModal] = useState(false)
    const [showCreateLessonModal, setShowCreateLessonModal] = useState(false)
    const [createLessonData, setCreateLessonData] = useState({})
    const [showEditLessonModal, setShowEditLessonModal] = useState(false)
    const [editLessonData, setEditLessonData] = useState({})
    const [showDeleteLessonModal, setShowDeleteLessonModal] = useState(false)
    const [deleteLessonData, setDeleteLessonData] = useState({})
    const [showLessonViewVideoModal, setShowLessonViewVideoModal] = useState(false)
    const [lessonViewVideoData, setLessonViewVideoData] = useState({})
    const [showLessonViewDocumentModal, setShowLessonViewDocumentModal] = useState(false)
    const [lessonViewDocumentData, setLessonViewDocumentData] = useState({})

    const [showQuestionOrderModal, setShowQuestionOrderModal] = useState(false)
    const [questionOrderData, setQuestionOrderData] = useState([])
    const [showQuestionModal, setShowQuestionModal] = useState(false)
    const [questionData, setQuestionData] = useState({})
    const [showCreateQuestionModal, setShowCreateQuestionModal] = useState(false)
    const [showEditQuestionModal, setShowEditQuestionModal] = useState(false)
    const [editQuestionData, setEditQuestionData] = useState({})
    const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false)
    const [deleteQuestionData, setDeleteQuestionData] = useState({})

    const [showRJQuestionOrderModal, setShowRJQuestionOrderModal] = useState(false)
    const [RJQuestionOrderData, setRJQuestionOrderData] = useState([])
    const [showRJQuestionModal, setShowRJQuestionModal] = useState(false)
    const [RJQuestionData, setRJQuestionData] = useState({})
    const [showCreateRJQuestionModal, setShowCreateRJQuestionModal] = useState(false)
    const [showEditRJQuestionModal, setShowEditRJQuestionModal] = useState(false)
    const [editRJQuestionData, setEditRJQuestionData] = useState({})
    const [showDeleteRJQuestionModal, setShowDeleteRJQuestionModal] = useState(false)
    const [deleteRJQuestionData, setDeleteRJQuestionData] = useState({})

    const [showLessonPopupQuizModal, setShowLessonPopupQuizModal] = useState(false)
    const [lessonPopupQuizData, setLessonPopupQuizData] = useState(false)
    const [showCreatePopupQuizModal, setShowCreatePopupQuizModal] = useState(false)
    const [showEditLessonPopupQuizModal, setShowEditLessonPopupQuizModal] = useState(false)
    const [editLessonPopupQuizData, setEditLessonPopupQuizData] = useState({})
    const [showDeleteLessonPopupQuizModal, setShowDeleteLessonPopupQuizModal] = useState(false)
    const [deleteLessonPopupQuizData, setDeleteLessonPopupQuizData] = useState({})

    const queryClient = useQueryClient()
    const { isLoading: isSectionsLoading, isError: isSectionsError, error: sectionsError, data: { data: sections } = {} } = useQuery(sectionQueryKey, () => getSections(courseId, true))
    const { mutate: sectionOrderMutate, isLoading: sectionOrderLoading } = useMutation(sections => {
        if (!sections) return true

        const data = {}
        sections.map((section, i) => data[section.id] = i + 1)

        return updateSectionSort(courseId, data)
    }, {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([sectionQueryKey])
            setShowSectionOrderModal(false)
        },
        onError: error => toast.error(error?.response?.data?.message)
    })
    const { mutate: deleteSectionMutate, isLoading: deleteSectionLoading } = useMutation(sectionId => deleteSection(courseId, sectionId), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([sectionQueryKey])
            setShowDeleteSectionModal(false)
            setDeleteSectionData({})
        },
        onError: error => toast.error(error?.response?.data?.message || error.message)
    })

    const { mutate: lessonOrderMutate, isLoading: lessonOrderLoading } = useMutation(lessons => {
        if (!lessons) return true

        const data = {}
        lessons.data.map((lesson, i) => data[lesson.id] = i + 1)

        return updateLessonSort(courseId, lessons.sectionId, data)
    }, {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([sectionQueryKey])
            setShowLessonOrderModal(false)
        },
        onError: error => toast.error(error?.response?.data?.message || error.message)
    })
    const { mutate: deleteLessonMutate, isLoading: deleteLessonLoading } = useMutation(lessonId => deleteLesson(courseId, deleteLessonData.section_id, lessonId), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([sectionQueryKey])
            setShowDeleteLessonModal(false)
            setDeleteLessonData({})
        },
        onError: error => toast.error(error?.response?.data?.message || error.message)
    })

    const { mutate: questionOrderMutate, isLoading: questionOrderLoading } = useMutation(questions => {
        if (!questions) return true

        const data = {}
        questions.map((question, i) => data[question.id] = i + 1)

        return updateQuestionSort(courseId, questionData.section_id, questionData.id, data)
    }, {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([`lessons-${questionData.id}-questions`])
            setShowQuestionOrderModal(false)
            setShowQuestionModal(true)
        },
        onError: error => toast.error(error?.response?.data?.message)
    })
    const { mutate: deleteQuestionMutate, isLoading: deleteQuestionLoading } = useMutation(questionId => deleteQuestion(courseId, deleteQuestionData.lesson.section_id, deleteQuestionData.lesson.id, questionId), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([`lessons-${deleteQuestionData.lesson.id}-questions`])
            setDeleteQuestionData({})
            setShowDeleteQuestionModal(false)
            setShowQuestionModal(true)
        },
        onError: error => toast.error(error?.response?.data?.message || error.message)
    })

    const { mutate: RJQuestionOrderMutate, isLoading: RJQuestionOrderLoading } = useMutation(questions => {
        if (!questions) return true

        const data = {}
        questions.map((question, i) => data[question.id] = i + 1)

        return updateRJQuestionSort(courseId, RJQuestionData.section_id, RJQuestionData.id, data)
    }, {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([`lessons-${RJQuestionData.id}-questions`])
            setShowRJQuestionOrderModal(false)
            setShowRJQuestionModal(true)
        },
        onError: error => toast.error(error?.response?.data?.message)
    })
    const { mutate: deleteRJQuestionMutate, isLoading: deleteRJQuestionLoading } = useMutation(questionId => deleteRJQuestion(courseId, deleteRJQuestionData.lesson.section_id, deleteRJQuestionData.lesson.id, questionId), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([`lessons-${deleteRJQuestionData.lesson.id}-questions`])
            setDeleteRJQuestionData({})
            setShowDeleteRJQuestionModal(false)
            setShowRJQuestionModal(true)
        },
        onError: error => toast.error(error?.response?.data?.message || error.message)
    })

    const { mutate: deletePopupQuizMutate, isLoading: deletePopupQuizLoading } = useMutation(popupQuizId => deleteLessonPopupQuiz(courseId, deleteLessonPopupQuizData.lesson.section_id, deleteLessonPopupQuizData.lesson.id, popupQuizId), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([`lessons-${deleteLessonPopupQuizData.lesson.id}-popup-quiz`])
            setDeleteLessonPopupQuizData({})
            setShowDeleteLessonPopupQuizModal(false)
            setShowLessonPopupQuizModal(true)
        },
        onError: error => toast.error(error?.response?.data?.message || error.message)
    })

    const getSortModalProps = () => {
        const props = {
            show: showSectionOrderModal || showLessonOrderModal || showQuestionOrderModal || showRJQuestionOrderModal,
            modalTitle: '',
            initialData: [],
            isLoading: false,
            isError: null,
            error: null,
            submitLoading: false,
            onSubmit: () => '',
            onHide: () => ''
        }

        if (showSectionOrderModal) {
            props.modalTitle = 'Set Section Orders'
            props.initialData = sections
            props.isLoading = isSectionsLoading
            props.isError = props.isSectionsError
            props.submitLoading = sectionOrderLoading
            props.onSubmit = data => sectionOrderMutate(data)
            props.onHide = () => setShowSectionOrderModal(false)
        } else if (showLessonOrderModal) {
            const selectedSectionIndex = sections.findIndex(section => section.id === showLessonOrderModal)
            if (selectedSectionIndex < 0) props.initialData = []

            props.modalTitle = 'Set Lesson Orders'
            props.initialData = sections[selectedSectionIndex].lessons
            props.submitLoading = lessonOrderLoading
            props.onSubmit = data => lessonOrderMutate({
                data,
                sectionId: showLessonOrderModal
            })
            props.onHide = () => setShowLessonOrderModal(false)
        } else if (showQuestionOrderModal) {
            props.modalTitle = 'Set Question Orders'
            props.initialData = questionOrderData
            props.titleKey = 'question'
            props.withNumbering = true
            props.submitLoading = questionOrderLoading
            props.onSubmit = data => questionOrderMutate(data)
            props.onHide = () => {
                setShowQuestionOrderModal(false)
                setShowQuestionModal(true)
            }
        } else if (showRJQuestionOrderModal) {
            props.modalTitle = 'Set Question Orders'
            props.initialData = RJQuestionOrderData
            props.titleKey = 'question'
            props.withNumbering = true
            props.submitLoading = RJQuestionOrderLoading
            props.onSubmit = data => RJQuestionOrderMutate(data)
            props.onHide = () => {
                setShowRJQuestionOrderModal(false)
                setShowRJQuestionModal(true)
            }
        }

        return props
    }

    const { mutate: copyPreTestToPostTestMutate, isLoading: copyPreTestToPostTestLoading } = useMutation(data => copyPreTestToPostTest(courseId, data.section_id, data.lesson_id), {
        onSuccess: () => {
            toast.success('Success')
        },
        onError: error => toast.error(error?.response?.data?.message || error.message)
    })

    return (
        <>
            <PageTitle breadcrumbs={breadcumbs}>Course Curriculum</PageTitle>
            <div className="card">
                <div className="card-body d-flex justify-content-between p-6">
                    <button type="button" className="btn btn-sm btn-light-primary" onClick={() => setShowSectionOrderModal(true)}><i className="fa fa-sort"></i> Set Section Orders</button>
                    <button type="button" className="btn btn-sm btn-primary" onClick={() => setShowCreateSectionModal(true)}>Add Section</button>
                </div>
            </div>
            {isSectionsLoading ? <PageSpinner height={300} /> : isSectionsError ? (
                <PageError height={300}>{sectionsError.message}</PageError>
            ) : (
                <div className="row justify-content-center mt-6">
                    <div className="col-12">
                        {sections.map((section, i) => (
                            <div key={i} className="card mb-6">
                                <div className="card-header p-6 bg-primary d-flex justify-content-between align-items-center">
                                    <div className="w-50">
                                        <span className="badge badge-square badge-light-primary p-3 mb-2">Section {i + 1}</span>
                                        <p className="fs-3 mb-0 fw-bold text-white">{section.title}</p>
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-sm btn-bg-light btn-color-primary me-3" onClick={() => {
                                            setShowCreateLessonModal(true)
                                            setCreateLessonData(section)
                                        }}>Add Lesson</button>
                                        {section.lessons.length > 1 && <button className="btn btn-sm me-3 px-2" title="Set Lesson Orders" onClick={() => setShowLessonOrderModal(section.id)}><i className="fa fa-sort text-white p-0"></i></button>}
                                        <button className="btn btn-sm me-3 px-2" title="Edit Section" onClick={() => {
                                            setShowEditSectionModal(true)
                                            setEditSectionData(section)
                                        }}><i className="fa fa-pencil text-white p-0"></i></button>
                                        <button className="btn btn-sm px-2" title="Delete Section" onClick={() => {
                                            setShowDeleteSectionModal(true)
                                            setDeleteSectionData(section)
                                        }}><i className="fa fa-trash text-white p-0"></i></button>
                                    </div>
                                </div>
                                <div className="card-body p-6">
                                    {section.lessons.length > 0 && section.lessons.map((lesson, ii) => (
                                        <div key={ii} className="border rounded px-6 py-3 mb-3 bg-hover-light">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <div>
                                                    <span className="badge badge-square badge-primary p-3 me-3">Lesson {lessonCount++}</span>
                                                    <span className="badge badge-square badge-light p-3 text-capitalize">
                                                        {lesson.type === 'quiz' ? 'Quiz - ' + unSlug(lesson.quiz_type) : lesson.type === 'practical_task' ? 'Practical Task - ' + unSlug(lesson.task_type) : unSlug(lesson.type)}
                                                    </span>
                                                </div>
                                                <div>
                                                    {lesson.type === 'video' ? (
                                                        <>
                                                            <button type="button" className="btn btn-sm px-2 me-3" onClick={() => {
                                                                setShowLessonViewVideoModal(true)
                                                                setLessonViewVideoData(lesson)
                                                            }}>
                                                                <i className="fa fa-play"></i>
                                                            </button>
                                                            <button type="button" className="btn btn-sm px-2 me-3" onClick={() => {
                                                                setShowLessonPopupQuizModal(true)
                                                                setLessonPopupQuizData(lesson)
                                                            }}>
                                                                <i className="fa fa-gamepad"></i>
                                                            </button>
                                                        </>
                                                    ) : lesson.type === 'document' ? (
                                                        // <button type="button" className="btn btn-sm px-2 me-3" onClick={() => {
                                                        //     setShowLessonViewDocumentModal(true)
                                                        //     setLessonViewDocumentData(lesson)
                                                        // }}>
                                                        //     <i className="fa fa-eye"></i>
                                                        // </button>
                                                        <></>
                                                    ) : null}
                                                    {lesson.type === 'quiz' && <button type="button" className="btn btn-sm px-2 me-3" onClick={() => {
                                                        setShowQuestionModal(true)
                                                        setQuestionData(lesson)
                                                    }}><i className="fa fa-rectangle-list text-muted p-0"></i></button>}
                                                    {(lesson.type === 'quiz' && lesson.quiz_type === 'pre_test') && <button type="button" className="btn btn-sm px-2 me-3" onClick={() => {
                                                        if (copyPreTestToPostTestLoading) return false
                                                        if (!window.confirm('Are you sure?')) return false

                                                        copyPreTestToPostTestMutate({ section_id: lesson.section_id, lesson_id: lesson.id })
                                                    }}><i className="fa fa-copy text-muted p-0"></i></button>}
                                                    {lesson.type === 'reflective_journal' && <button type="button" className="btn btn-sm px-2 me-3" onClick={() => {
                                                        setShowRJQuestionModal(true)
                                                        setRJQuestionData(lesson)
                                                    }}><i className="fa fa-rectangle-list text-muted p-0"></i></button>}
                                                    <button type="button" className="btn btn-sm px-2 me-3" onClick={() => {
                                                        setShowEditLessonModal(true)
                                                        setEditLessonData({ section, lesson })
                                                    }}><i className="fa fa-pencil text-muted p-0"></i></button>
                                                    <button type="button" className="btn btn-sm px-2" onClick={() => {
                                                        setShowDeleteLessonModal(true)
                                                        setDeleteLessonData(lesson)
                                                    }}><i className="fa fa-trash text-danger p-0"></i></button>
                                                </div>
                                            </div>
                                            <p className="fw-bold fs-4 mb-0">{lesson.title}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <SortModal {...getSortModalProps()} />
            <CreateSectionModal
                queryClient={queryClient}
                courseId={courseId}
                show={showCreateSectionModal}
                onHide={() => setShowCreateSectionModal(false)}
            />
            <EditSectionModal
                queryClient={queryClient}
                courseId={courseId}
                section={editSectionData}
                show={showEditSectionModal}
                onHide={() => {
                    setShowEditSectionModal(false)
                    setEditSectionData({})
                }}
            />
            <CreateLessonModal
                queryClient={queryClient}
                section={createLessonData}
                show={showCreateLessonModal}
                onHide={() => setShowCreateLessonModal(false)}
            />
            <EditLessonModal
                queryClient={queryClient}
                section={editLessonData.section}
                lesson={editLessonData.lesson}
                show={showEditLessonModal}
                onHide={() => {
                    setShowEditLessonModal(false)
                    setEditLessonData({})
                }}
            />
            <LessonViewVideoModal
                show={showLessonViewVideoModal}
                lesson={lessonViewVideoData}
                onHide={() => {
                    setShowLessonViewVideoModal(false)
                    setLessonViewVideoData({})
                }}
            />
            <LessonViewDocumentModal
                show={showLessonViewDocumentModal}
                lesson={lessonViewDocumentData}
                onHide={() => {
                    setShowLessonViewDocumentModal(false)
                    setLessonViewDocumentData({})
                }}
            />
            <CourseQuestionModal
                lesson={questionData}
                show={showQuestionModal}
                onHide={() => {
                    setShowQuestionModal(false)
                    setQuestionData({})
                }}
                onQuestionOrders={data => {
                    setQuestionOrderData(data)
                    setShowQuestionOrderModal(true)
                    setShowQuestionModal(false)
                }}
                onCreateQuestion={() => {
                    setShowCreateQuestionModal(true)
                    setShowQuestionModal(false)
                }}
                onEditQuestion={data => {
                    setEditQuestionData(data)
                    setShowEditQuestionModal(true)
                    setShowQuestionModal(false)
                }}
                onDeleteQuestion={data => {
                    setDeleteQuestionData(data)
                    setShowDeleteQuestionModal(true)
                    setShowQuestionModal(false)
                }}
            />
            <CreateQuestionModal
                queryClient={queryClient}
                lesson={questionData}
                show={showCreateQuestionModal}
                onHide={() => {
                    setShowCreateQuestionModal(false)
                    setShowQuestionModal(true)
                }}
            />
            <EditQuestionModal
                queryClient={queryClient}
                lesson={questionData}
                question={editQuestionData}
                show={showEditQuestionModal}
                onHide={() => {
                    setShowEditQuestionModal(false)
                    setEditQuestionData({})
                    setShowQuestionModal(true)
                }}
            />
            <CourseRJQuestionModal
                lesson={RJQuestionData}
                show={showRJQuestionModal}
                onHide={() => {
                    setShowRJQuestionModal(false)
                    setRJQuestionData({})
                }}
                onQuestionOrders={data => {
                    setRJQuestionOrderData(data)
                    setShowRJQuestionOrderModal(true)
                    setShowRJQuestionModal(false)
                }}
                onCreateQuestion={() => {
                    setShowCreateRJQuestionModal(true)
                    setShowRJQuestionModal(false)
                }}
                onEditQuestion={data => {
                    setEditRJQuestionData(data)
                    setShowEditRJQuestionModal(true)
                    setShowRJQuestionModal(false)
                }}
                onDeleteQuestion={data => {
                    setDeleteRJQuestionData(data)
                    setShowDeleteRJQuestionModal(true)
                    setShowRJQuestionModal(false)
                }}
            />
            <CreateRJQuestionModal
                queryClient={queryClient}
                lesson={RJQuestionData}
                show={showCreateRJQuestionModal}
                onHide={() => {
                    setShowCreateRJQuestionModal(false)
                    setShowRJQuestionModal(true)
                }}
            />
            <EditRJQuestionModal
                queryClient={queryClient}
                lesson={RJQuestionData}
                question={editRJQuestionData}
                show={showEditRJQuestionModal}
                onHide={() => {
                    setShowEditRJQuestionModal(false)
                    setEditRJQuestionData({})
                    setShowRJQuestionModal(true)
                }}
            />
            <LessonPopupQuizModal
                lesson={lessonPopupQuizData}
                show={showLessonPopupQuizModal}
                onHide={() => {
                    setShowLessonPopupQuizModal(false)
                    setLessonPopupQuizData({})
                }}
                onCreatePopupQuiz={() => {
                    setShowCreatePopupQuizModal(true)
                    setShowLessonPopupQuizModal(false)
                }}
                onEditPopupQuiz={data => {
                    setEditLessonPopupQuizData(data)
                    setShowEditLessonPopupQuizModal(true)
                    setShowLessonPopupQuizModal(false)
                }}
                onDeletePopupQuiz={data => {
                    setDeleteLessonPopupQuizData(data)
                    setShowDeleteLessonPopupQuizModal(true)
                    setShowLessonPopupQuizModal(false)
                }}
            />
            <CreatePopupQuizModal
                queryClient={queryClient}
                lesson={lessonPopupQuizData}
                show={showCreatePopupQuizModal}
                onHide={() => {
                    setShowCreatePopupQuizModal(false)
                    setShowLessonPopupQuizModal(true)
                }}
            />
            <EditPopupQuizModal
                queryClient={queryClient}
                lesson={lessonPopupQuizData}
                popupQuiz={editLessonPopupQuizData}
                show={showEditLessonPopupQuizModal}
                onHide={() => {
                    setShowEditLessonPopupQuizModal(false)
                    setEditLessonPopupQuizData({})
                    setShowLessonPopupQuizModal(true)
                }}
            />
            <DeleteConfirmModal
                show={showDeleteSectionModal}
                handleClose={() => {
                    setShowDeleteSectionModal(false)
                    setDeleteSectionData({})
                }}
                onConfirm={() => deleteSectionMutate(deleteSectionData.id)}
                isConfirmLoading={deleteSectionLoading}
                name={deleteSectionData?.title}
            />
            <DeleteConfirmModal
                show={showDeleteLessonModal}
                handleClose={() => {
                    setShowDeleteLessonModal(false)
                    setDeleteLessonData({})
                }}
                onConfirm={() => deleteLessonMutate(deleteLessonData.id)}
                isConfirmLoading={deleteLessonLoading}
                name={deleteLessonData?.title}
            />
            <DeleteConfirmModal
                show={showDeleteQuestionModal}
                handleClose={() => {
                    setShowDeleteQuestionModal(false)
                    setDeleteQuestionData({})
                    setShowQuestionModal(true)
                }}
                onConfirm={() => deleteQuestionMutate(deleteQuestionData.question.id)}
                isConfirmLoading={deleteQuestionLoading}
            />
            <DeleteConfirmModal
                show={showDeleteRJQuestionModal}
                handleClose={() => {
                    setShowDeleteRJQuestionModal(false)
                    setDeleteRJQuestionData({})
                    setShowRJQuestionModal(true)
                }}
                onConfirm={() => deleteRJQuestionMutate(deleteRJQuestionData.question.id)}
                isConfirmLoading={deleteRJQuestionLoading}
            />
            <DeleteConfirmModal
                show={showDeleteLessonPopupQuizModal}
                handleClose={() => {
                    setShowDeleteLessonPopupQuizModal(false)
                    setDeleteLessonPopupQuizData({})
                    setShowLessonPopupQuizModal(true)
                }}
                onConfirm={() => deletePopupQuizMutate(deleteLessonPopupQuizData.popupQuiz.id)}
                isConfirmLoading={deletePopupQuizLoading}
            />
        </>
    )
}

export default Authorization(CourseCurriculum, ['admin', 'officer', 'user'])