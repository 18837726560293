import { useQuery, useQueryClient } from "react-query"
import { useOutletContext } from "react-router-dom"
import { useEffect, useState } from "react"
import Select from "react-select"

import { getScheduleSessions } from "../../../../../models/courses/schedules"
import Table from "../../../../../components/Table/Table"
import GeneralPagination from "../../../../../components/Pagination/GeneralPagination"
import { sessionColumns, columnAliases } from "./components/SessionsTable/_columns"
import { Helmet } from "react-helmet"

export default function Sessions() {
    const { schedule } = useOutletContext()

    const pageSize = 10
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({
        id: 'start_at',
        direction: 'ASC'
    })

    const queryClient = useQueryClient()
    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery([`schedule-${schedule.id}-sessions`], () => getScheduleSessions({
        scheduleId: schedule.id,
        status: filters?.status?.value,
        page,
        rows: pageSize,
        sortBy
    }))

    useEffect(() => {
        queryClient.fetchQuery(`schedule-${schedule.id}-sessions`)
    }, [queryClient, filters, page, sortBy])

    return (
        <>
            <Helmet>
                <title>Sessions - {schedule.schedule_code} - {schedule.course?.title}</title>
            </Helmet>
            <div className="min-h-500px">
                <div className="card">
                    <div className="card-header p-6 row justify-content-end">
                        <div className="col-12 col-md-4">
                            <Select
                                isClearable
                                isSearchable={false}
                                name="status"
                                onChange={option => setFilters((prev) => ({
                                    ...prev,
                                    status: option
                                }))}
                                value={filters?.status}
                                options={[
                                    { label: 'Upcoming', value: 'inactive' },
                                    { label: 'On Going', value: 'active' },
                                    { label: 'Finished', value: 'finished' },
                                ]}
                                placeholder="Status"
                            />
                        </div>
                    </div>
                    <div className="card-body p-6">
                        <Table
                            columns={sessionColumns}
                            isLoading={isFetching}
                            isError={isError}
                            error={error}
                            data={!isLoading ? data.data : []}
                            currentPage={page}
                            pageSize={pageSize}
                            defaultSortBy={{
                                id: 'start_at',
                                desc: false
                            }}
                            onSortByChange={sortBy => setSortBy(() => {
                                if (sortBy.length) return {
                                    id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                    direction: sortBy[0].desc ? 'DESC' : 'ASC'
                                }
                        
                                return {}
                            })}
                        />
                        
                        {isSuccess && <GeneralPagination
                            onPageChange={page => setPage(page)}
                            totalCount={data?.meta.total}
                            currentPage={page}
                            pageSize={pageSize}
                            from={data?.meta.from}
                            to={data?.meta.to}
                        />}
                    </div>
                </div>
            </div>
        </>
    )
}
