import { useFormik } from "formik"
import { Modal } from "react-bootstrap"
import { useMutation } from "react-query"
import * as Yup from "yup"
import { updatePassword } from "../../../models/users"
import Spinner from "../../../components/Spinner/Spinner"
import { toast } from "react-toastify"


const schema = Yup.object().shape({
    password: Yup.string().min(8).max(20).required().label('Password'),
    password_confirmation: Yup.string().min(8).max(20).required().oneOf([Yup.ref("password"), null], "Confirmation password doesn't match").label('Password Confirmation')
})

const ResetPasswordModal = ({ show, onHide, user }) => {
    const formik = useFormik({
        initialValues: {
            password: '',
            password_confirmation: ''
        },
        validationSchema: schema,
        onSubmit: values => mutate(values.password)
    })

    const { isLoading, mutate } = useMutation(data => updatePassword(user.id, data), {
        onError: () => toast.error('Failed to reset password'),
        onSuccess: () => {
            formik.resetForm()
            toast.success('Password resetted')
            onHide()
        }
    })

    return (
        <Modal show={show} onHide={onHide} centered keyboard={false} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Reset Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="fw-bold fs-3 mb-0">{user.name}</p>
                <p className="text-muted mb-0">{user.email}</p>
                <div className="separator my-6"></div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                        <label className="form-label required">Password</label>
                        <input type="password" name="password" className="form-control" value={formik.values.password} onChange={formik.handleChange} />
                        {formik.errors.password && <small className="form-text text-danger">{formik.errors.password}</small>}
                    </div>
                    <div className="form-group mt-4">
                        <label className="form-label required">Password Confirmation</label>
                        <input type="password" name="password_confirmation" className="form-control" value={formik.values.password_confirmation} onChange={formik.handleChange} />
                        {formik.errors.password_confirmation && <small className="form-text text-danger">{formik.errors.password_confirmation}</small>}
                    </div>
                    <button type="submit" className="btn btn-primary w-100 mt-8" disabled={!formik.isValid || isLoading}>
                        Reset Password {isLoading && <Spinner size="sm" className="ml-1" />}
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ResetPasswordModal