import { useOutletContext } from "react-router-dom"
import { useEffect, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { getWithdrawals } from "../../../../models/tunjangan-hari-karya"

import Table from "../../../../components/Table/Table"
import WithdrawFilter from "./components/WithdrawFilter"
import { withdrawColumns, columnAliases } from "./components/WithdrawTable/_columns"
import Authorization from "../../../../routing/Authorization"
import GeneralPagination from "../../../../components/Pagination/GeneralPagination"
import WithdrawDetailModal from "./components/WithdrawDetailModal"


const Withdrawals = () => {
    const { campaign } = useOutletContext()
    const queryKey = `tunjangan-hari-karya-${campaign.id}-withdrawals`

    const pageSize = 10
    const [showFilter, setShowFilter] = useState(true)
    const [filters, setFilters] = useState({
        status: 'pending'
    })
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({})

    const [showWithdrawDetailModal, setShowWithdrawDetailModal] = useState(false)
    const [withdrawDetailData, setWithdrawDetailData] = useState({})

    const queryClient = useQueryClient()
    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery([queryKey], () => getWithdrawals({
        campaignId: campaign.id,
        fullName: filters?.full_name,
        ewallet: filters?.ewallet,
        ewalletAddress: filters?.ewallet_address,
        status: filters?.status,
        page,
        rows: pageSize,
        sortBy
    }))

    useEffect(() => {
        queryClient.fetchQuery([queryKey])
    }, [queryClient, filters, page, sortBy])

    return (
        <>
            <div className="min-h-500px">
                <div className="card">
                    <div className="card-header p-6 d-block">
                        <div className="d-flex justify-content-between">
                            <button type="button" className={"btn btn-sm me-3 " + (showFilter ? 'btn-primary' : 'btn-light-primary')} onClick={() => setShowFilter(item => !item)}><i className="fa fa-filter"></i> Filter</button>
                        </div>
                        <WithdrawFilter
                            show={showFilter}
                            onSubmit={values => {
                                setPage(1)
                                setFilters(values)
                            }}
                        />
                    </div>
                    <div className="card-body p-6">
                        <Table
                            isClickableRow
                            columns={withdrawColumns}
                            isLoading={isFetching}
                            isError={isError}
                            error={error}
                            data={!isLoading ? data.data : []}
                            currentPage={page}
                            pageSize={pageSize}
                            defaultSortBy={{
                                id: 'updated_at',
                                desc: true
                            }}
                            onSortByChange={sortBy => setSortBy(() => {
                                if (sortBy.length) return {
                                    id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                    direction: sortBy[0].desc ? 'DESC' : 'ASC'
                                }
                        
                                return {}
                            })}
                            onRowClick={row => {
                                setShowWithdrawDetailModal(true)
                                setWithdrawDetailData(row)
                            }}
                        />
                        
                        {isSuccess && <GeneralPagination
                            onPageChange={page => setPage(page)}
                            totalCount={data?.meta.total}
                            currentPage={page}
                            pageSize={pageSize}
                            from={data?.meta.from}
                            to={data?.meta.to}
                        />}
                    </div>
                </div>
            </div>

            <WithdrawDetailModal
                show={showWithdrawDetailModal}
                onHide={() => {
                    setWithdrawDetailData({})
                    setShowWithdrawDetailModal(false)
                }}
                queryClient={queryClient}
                queryKey={queryKey}
                campaign={campaign}
                withdraw={withdrawDetailData}
            />
        </>
    )
}

export default Authorization(Withdrawals, ['admin', 'officer'])