import { Modal } from "react-bootstrap"
import { useMutation, useQuery } from "react-query"
import moment from "moment"

import { parseDigitalPlatforms } from "../../../../../helpers/tunjangan-hari-karya"
import { getApplicant, updateApplicant } from "../../../../../models/tunjangan-hari-karya"
import PageSpinner from "../../../../../components/Spinner/PageSpinner"
import PageError from "../../../../../components/Error/PageError"
import { calculatePercentage, durationToText, formatDate, toRupiah } from "../../../../../helpers/common"
import { Field, FormikProvider, useFormik } from "formik"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"


const ApplicantObjective = ({ formik, objective, disabled = false }) => {
    return (
        <div className="rounded border">
            <div className="p-6 d-flex justify-content-between align-items-center border-bottom">
                <div>
                    <h5 className="mb-0">{objective.objective.title}</h5>
                </div>
                <div className="form-check form-switch form-check-custom form-check-solid">
                    <Field name={`objectives.${objective.objective_id}`} className="form-check-input" type="checkbox" onChange={e => formik.setFieldValue(`objectives.${objective.objective_id}`, e.target.checked)} disabled={disabled} />
                </div>
            </div>
            {!formik.values.objectives[objective.objective_id] && (
                <div className="border-bottom form-group">
                    <input type="text" name={`remarks.${objective.objective_id}`} className="form-control form-control-sm border-0" placeholder="Remark" value={formik.values.remarks[objective.objective_id]} onChange={formik.handleChange} />
                </div>
            )}
            {['REDEEM_1', 'REDEEM_2'].includes(objective.objective_id) ? (
                <ApplicantObjectiveRedeem attributes={objective.attributes} />
            ) : ['COMPLETE_1', 'COMPLETE_2'].includes(objective.objective_id) ? (
                <ApplicantObjectiveComplete attributes={objective.attributes} />
            ) : ['PRAKERJA_REVIEW_1', 'PRAKERJA_REVIEW_2'].includes(objective.objective_id) ? (
                <ApplicantObjectivePrakerjaReview attributes={objective.attributes} />
            ) : ['DISCIPLINE_1', 'DISCIPLINE_2'].includes(objective.objective_id) ? (
                <ApplicantObjectiveDiscipline attributes={objective.attributes} />
            ) : ['UK_GT_90_1', 'UK_GT_90_2'].includes(objective.objective_id) ? (
                <ApplicantObjectiveUkGt90 attributes={objective.attributes} />
            ) : objective.objective_id === 'ATTEND_EXTRA_WEBINAR' ? (
                <ApplicantObjectiveAttendExtraWebinar attributes={objective.attributes} />
            ) : null}
        </div>
    )
}

const ApplicantObjectiveRedeem = ({ attributes }) => {
    return (
        <div className="p-6 row">
            <div className="col-12 col-md-6">
                <p className="fw-semibold mb-0">Course</p>
                <p className="mb-0">{attributes?.course_title}</p>
            </div>
            <div className="col-12 col-md-6">
                <p className="fw-semibold mb-0">Redeem at</p>
                <p className="mb-0">{formatDate(attributes?.redeem_at)}</p>
            </div>
            <div className="col-12 col-md-6 mt-3">
                <p className="fw-semibold mb-0">Voucher Code</p>
                <p className="mb-0">{attributes?.voucher_code}</p>
            </div>
            <div className="col-12 col-md-6 mt-3">
                <p className="fw-semibold mb-0">Redeem Code</p>
                <p className="mb-0">{attributes?.redeem_code}</p>
            </div>
        </div>
    )
}

const ApplicantObjectiveComplete = ({ attributes }) => {
    return (
        <div className="p-6 row">
            <div className="col-12 col-md-6">
                <p className="fw-semibold mb-0">Course</p>
                <p className="mb-0">{attributes?.course_title}</p>
            </div>
            <div className="col-12 col-md-6">
                <p className="fw-semibold mb-0">Completed at</p>
                <p className="mb-0">{formatDate(attributes?.completed_at)}</p>
            </div>
        </div>
    )
}

const ApplicantObjectivePrakerjaReview = ({ attributes }) => {
    return (
        <div className="p-6">
            {attributes?.file_url ? (
                <a href={attributes.file_url} target="_blank" rel="noreferrer">
                    <img src={attributes.file_url} className="h-100px rounded" />
                </a>
            ) : '-'}
        </div>
    )
}

const ApplicantObjectiveDiscipline = ({ attributes }) => {
    if (!attributes?.course_title) return <div className="p-6">-</div>

    return (
        <div className="p-6">
            <h6>{attributes.course_title}</h6>
            <div className="table-responsive">
                <table className="table table-bordered border-bottom gy-4 gx-4">
                    <thead>
                        <tr className="fw-bold text-gray-800">
                            <th width="40%">Session</th>
                            <th className="text-center">Start at</th>
                            <th className="text-center">Join at</th>
                            <th className="text-center">Join Duration</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attributes.sessions.map(session => (
                            <tr>
                                <td><a href={`https://dashboard.bebasjamkerja.com/courses/schedules/${session.schedule_id}/sessions/${session.id}`} target="_blank" rel="noreferrer">{session.title}</a></td>
                                <td className="text-center align-middle">{moment.utc(session.start_at).local().format('HH:mm')}</td>
                                <td className="text-center align-middle">{session.join_at ? moment.utc(session.start_at).local().format('HH:mm') : '-'}</td>
                                <td className="text-center align-middle">{session.join_duration !== null ? durationToText(session.join_duration) + ' (' + calculatePercentage(session.join_duration, session.total_duration).toFixed(0) + '%)' : '-'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const ApplicantObjectiveUkGt90 = ({ attributes }) => {
    return (
        <div className="p-6 row">
            <div className="col-12 col-md-6">
                <p className="fw-semibold mb-0">Course</p>
                <p className="mb-0">{attributes?.course_title}</p>
            </div>
            <div className="col-12 col-md-6">
                <p className="fw-semibold mb-0">Score</p>
                <p className="mb-0">{attributes.skill_test_score}</p>
            </div>
        </div>
    )
}

const ApplicantObjectiveAttendExtraWebinar = ({ attributes }) => {
    return (
        <div className="p-6">
            <a href={attributes.file_url} target="_blank" rel="noreferrer">
                <img src={attributes.file_url} className="h-100px rounded" />
            </a>
        </div>
    )
}

const ApplicantDetailForm = ({ campaign, applicant, objectives, onSetValues, total, onSetTotal }) => {
    const formik = useFormik({
        initialValues: {
            objectives: Object.keys(objectives).reduce((acc, objective) => {
                acc[objective] = objectives[objective].status === 'approved'
                return acc
            }, {}),
            remarks: Object.keys(objectives).reduce((acc, objective) => {
                acc[objective] = objectives[objective].message
                return acc
            }, {})
        },
        enableReinitialize: true
    })

    const calculateTotal = () => {
        let tempTotal = 0;

        Object.keys(formik.values.objectives).map(objective => {
            if (formik.values.objectives[objective]) tempTotal = tempTotal + parseInt(objectives[objective]?.value)
        })

        onSetTotal(tempTotal)
    }

    useEffect(() => {
        calculateTotal()
    }, [formik.values.objectives])

    useEffect(() => {
        onSetValues(formik.values)
    }, [formik.values])

    return (
        <form className="flex-fill row overflow-auto" onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
                <div className="col-md-3 overflow-auto h-100">
                    <div className="d-flex flex-column px-6 py-3 gap-3">
                        <div>
                            <p className="mb-0 fw-semibold">Batch</p>
                            <p className="mb-0">{formatDate(campaign.start_date, false)} - {formatDate(campaign.end_date, false)}</p>
                        </div>
                        <div>
                            <p className="mb-0 fw-semibold">Full Name</p>
                            <p className="mb-0">{applicant.full_name}</p>
                        </div>
                        <div>
                            <p className="mb-0 fw-semibold">Gender</p>
                            <p className="mb-0">{applicant.gender === 'M' ? 'Male' : 'Female'}</p>
                        </div>
                        <div>
                            <p className="mb-0 fw-semibold">City</p>
                            <p className="mb-0">{applicant.city}</p>
                        </div>
                        <div>
                            <p className="mb-0 fw-semibold">Phone No</p>
                            <p className="mb-0">{applicant.phone_no}</p>
                        </div>
                        <div>
                            <p className="mb-0 fw-semibold">Prakerja Batch</p>
                            <p className="mb-0">{applicant.prakerja_batch}</p>
                        </div>
                        <div>
                            <p className="mb-0 fw-semibold">Digital Platforms</p>
                            <p className="mb-0">{parseDigitalPlatforms(applicant.digital_platforms_full)}</p>
                        </div>
                        <div>
                            <p className="mb-0 fw-semibold">Ewallet</p>
                            <p className="mb-0 text-capitalize">{applicant.ewallet}</p>
                        </div>
                        <div>
                            <p className="mb-0 fw-semibold">Ewallet Address</p>
                            <p className="mb-0">{applicant.ewallet_address}</p>
                        </div>
                        {applicant.prakerja_balance_url && (
                            <div>
                                <p className="mb-0 fw-semibold mb-1">Prakerja Balance</p>
                                <img src={applicant.prakerja_balance_url} className="w-100 rounded" />
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-md-6 overflow-auto px-6 py-3 h-100">
                    <div className="d-flex flex-column gap-6">
                        {Object.keys(objectives).map(objective => <ApplicantObjective formik={formik} objective={objectives[objective]} disabled={applicant?.withdraw_status === 'success'} />)}
                    </div>
                </div>
                <div className="col-md-3 px-6 py-3 d-flex flex-column ps-0 h-100">
                    <div className="flex-fill overflow-auto d-flex flex-column gap-3 pb-6">
                        {Object.keys(formik.values.objectives).map(objective => (
                            <div>
                                <div className="d-flex gap-3 align-items-center">
                                    <div className="w-10px flex-shrink-0">
                                        {formik.values.objectives[objective] ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-danger"></i>}
                                    </div>
                                    <p className="flex-fill mb-0 fw-semibold">{objectives[objective].objective.title}</p>
                                    {formik.values.objectives[objective] ? <p className="mb-0 fw-bold text-success flex-shrink-0">+{toRupiah(objectives[objective].value)}</p> : <p className="mb-0 fw-bold text-muted flex-shrink-0">Rp 0</p>}
                                </div>
                                {formik.values.remarks[objective] && <p className="mb-0 text-muted ms-6">{formik.values.remarks[objective]}</p>}
                            </div>
                        ))}
                    </div>
                    <div className="border-top pt-5 pb-2 d-flex justify-content-between">
                        <h4 className="mb-0">Total</h4>
                        <h4 className="mb-0">{toRupiah(total)}</h4>
                    </div>
                </div>
            </FormikProvider>
        </form>
    )
}


const ApplicantDetailModal = ({ show, onHide, queryClient, queryKey, campaign, applicant }) => {
    const [values, setValues] = useState({})
    const [total, setTotal] = useState(0)
    
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [confirmationMessage, setConfirmationMessage] = useState(null)

    const { isLoading, isError, error, data: objectives, isFetched } = useQuery([queryKey, applicant?.user_id], () => getApplicant(campaign.id, applicant?.user_id), {
        enabled: !!applicant?.user_id,
    })

    const { isLoading: submitIsLoading, mutate: submitMutate} = useMutation(data => updateApplicant(campaign.id, applicant?.user_id, data), {
        onSuccess: () => {
            toast.success('Applicant Updated')
            queryClient.invalidateQueries([queryKey])
            queryClient.invalidateQueries(['tunjangan-hari-karya', campaign.id])
            hideModal()
        },
        onError: () => toast.error('Failed to update applicant')
    })

    const hideModal = () => {
        setShowConfirmation(false)
        setConfirmationMessage(null)
        onHide()
    }

    const approveParticipant = () => submitMutate({...values, status: 'approve'})

    const rejectParticipant = (openForm = false) => submitMutate({...values, message: confirmationMessage, form_status: openForm ? 'open' : 'close', status: 'reject'})

    return (
        <>
            <Modal show={show} onHide={() => hideModal()} size="fullscreen" backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex gap-3">
                        <span>Applicant Detail</span>
                        {applicant?.status === 'pending' ? <span className="badge badge-light">Pending</span> : applicant?.status === 'approved' ? <span className="badge badge-success">Approved</span> : applicant?.status === 'rejected' ? <span className="badge badge-danger">Rejected</span> : applicant?.status === 'resubmit' ? <span className="badge badge-warning">Resubmit</span> : null}
                        {applicant?.status === 'approved' ? applicant?.withdraw_status === 'pending' ? <span className="badge badge-light">Withdraw Pending</span> : applicant?.withdraw_status === 'success' ? <span className="badge badge-success">Withdraw Success</span> : applicant?.withdraw_status === 'failed' ? <span className="badge badge-danger">Withdraw Failed</span> : null : null}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="container-fluid d-flex flex-column h-100">
                        <div className="row p-6">
                            <div className="col-md-3">
                                <h4 className="mb-0">Personal Informations</h4>
                            </div>
                            <div className="col-md-6">
                                <h4 className="mb-0">Checklist</h4>
                            </div>
                            <div className="col-md-3">
                                <h4 className="mb-0">Summary</h4>
                            </div>
                        </div>
                        {isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : isFetched ? (
                            <ApplicantDetailForm campaign={campaign} applicant={applicant} objectives={objectives} values={values} onSetValues={values => setValues(values)} total={total} onSetTotal={total => setTotal(total)} />
                        ) : <></>}
                    </div>
                </Modal.Body>
                {applicant?.withdraw_status !== 'success' && (
                    <Modal.Footer className="d-flex justify-content-between align-items-center gap-3 py-3">
                        <button type="button" className="btn btn-sm btn-light-danger">Delete Applicant</button>
                        {showConfirmation ? (
                            <div className="d-flex align-items-center gap-3">
                                {showConfirmation === 'approve' ? (
                                    <>
                                        <p className="mb-0 fw-semibold">Approve Applicant?</p>
                                        <button type="button" className="btn btn-sm btn-success" disabled={submitIsLoading} onClick={() => approveParticipant()}>Yes, Approve</button>
                                        <button type="button" className="btn btn-sm btn-light" disabled={submitIsLoading} onClick={() => setShowConfirmation(false)}>Cancel</button>
                                    </>
                                ) : showConfirmation === 'reject' ? (
                                    <>
                                        <p className="mb-0 fw-semibold">Reject Applicant</p>
                                        <input className="form-control form-control-sm w-300px" placeholder="Message" onChange={e => setConfirmationMessage(e.target.value)} value={confirmationMessage} />
                                        <button type="button" className="btn btn-sm btn-danger" disabled={!confirmationMessage || submitIsLoading} onClick={() => rejectParticipant()}>Reject</button>
                                        <button type="button" className="btn btn-sm btn-warning" disabled={!confirmationMessage || submitIsLoading} onClick={() => rejectParticipant(true)}>Reject (Reopen Form)</button>
                                        <button type="button" className="btn btn-sm btn-light" disabled={submitIsLoading} onClick={() => {
                                            setShowConfirmation(false)
                                            setConfirmationMessage(null)
                                        }}>Cancel</button>
                                    </>
                                ) : null}
                            </div>
                        ) : (
                            <div>
                                <button type="button" className="btn btn-sm btn-danger" onClick={() => setShowConfirmation('reject')}>Reject Applicant</button>
                                <button type="button" className="btn btn-sm btn-success ms-2" disabled={total < 1} onClick={() => setShowConfirmation('approve')}>Approve Applicant</button>
                            </div>
                        )}
                    </Modal.Footer>
                )}
            </Modal>
        </>
    )
}

export default ApplicantDetailModal