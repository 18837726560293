import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { toast } from "react-toastify"
import clsx from "clsx"

import { checkIsActive } from "../../../../_metronic/helpers"
import PageSpinner from "../../../components/Spinner/PageSpinner"
import PageError from "../../../components/Error/PageError"
import { getCampaign } from "../../../models/tunjangan-hari-karya"
import { formatDate } from "../../../helpers/common"
import { ProgressBar } from "react-bootstrap"


const MenuListItem = ({ to, children }) => {
    const { pathname } = useLocation()

    return (
        <li className="nav-item mt-2">
            <Link
                className={clsx("nav-link text-active-primary ms-0 me-10 py-5", {
                    "active": checkIsActive(pathname, to)
                })}
                to={to}
            >
                {children}
            </Link>
        </li>
    )
}

export default function TunjanganHariKaryaCard() {
    const { campaignId } = useParams()
    const navigate = useNavigate()

    const { isLoading, isError, error, data: { data: campaign } = {} } = useQuery(['tunjangan-hari-karya', campaignId], () => getCampaign(campaignId), {
        retry: 0,
        onError: error => {
            if (error.response.status === 404) {
                toast.error('Campaign not found')
                navigate('/campaigns/tunjangan-hari-karya')
            }
        }
    })

    return isLoading ? <PageSpinner height={300} /> : isError ? (
        <PageError height={300}>{error.message}</PageError>
    ) : (
        <>
            <div className="card">
                <div className="card-body pt-9 pb-0">
                    <div className="d-flex justify-content-between align-items-end">
                        <div>
                            {campaign.status === 'inactive' && <span className="badge badge-square badge-light p-3">Campaign Inactive</span>}
                            {campaign.status === 'active' && <span className="badge badge-square badge-light-primary p-3">Campaign active</span>}
                            {campaign.form_status === 'inactive' && <span className="badge badge-square badge-light p-3 ms-2">Form Inactive</span>}
                            {campaign.form_status === 'active' && <span className="badge badge-square badge-light-primary p-3 ms-2">Form active</span>}
                            <h3 className="mt-3 mb-0">{formatDate(campaign.start_date, false)} - {formatDate(campaign.end_date, false)}</h3>
                        </div>
                        <div className="w-25">
                            <div className="d-flex justify-content-between mb-1">
                                <p className="fw-semibold mb-0">{((campaign.participant_count / campaign.quota) * 100).toFixed(1)}%</p>
                                <p className="fw-semibold mb-0">{campaign.participant_count} / {campaign.quota}</p>
                            </div>
                            <ProgressBar variant="primary" now={campaign.participant_count} min={0} max={campaign.quota} />
                        </div>
                    </div>
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                        <MenuListItem
                            to={`/campaign/tunjangan-hari-karya/${campaignId}/overview`}
                        >
                            Overview
                        </MenuListItem>
                        <MenuListItem
                            to={`/campaign/tunjangan-hari-karya/${campaignId}/applicants`}
                        >
                            Applicants
                        </MenuListItem>
                        <MenuListItem
                            to={`/campaign/tunjangan-hari-karya/${campaignId}/withdrawals`}
                        >
                            Withdrawals
                        </MenuListItem>
                    </ul>
                </div>
            </div>
            <div className="mt-6">
                <Outlet context={{ campaign }} />
            </div>
        </>
    )
}
