import { useOutletContext } from "react-router-dom"
import { useAuth } from "../../../../modules/auth"
import Authorization from "../../../../routing/Authorization"
import TasksComponent from "../../tasks/components/TasksComponent"
import { Helmet } from "react-helmet"


const Tasks = () => {
    const { schedule } = useOutletContext()
    const { auth } = useAuth()

    return (
        <>
            <Helmet>
                <title>Tasks - {schedule.schedule_code} - {schedule.course?.title}</title>
            </Helmet>
            <div className="min-h-500px">
                <TasksComponent schedule={schedule} />
            </div>
        </>
    )
}

export default Authorization(Tasks, ['admin', 'officer'])