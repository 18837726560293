import { userProfilePicture } from "../../../../helpers/pictures"

const NameCell = ({ provider, name, profilePicture = null }) => {
    return (
        <div className="d-flex gap-3 align-items-center">
            <div className='symbol symbol-30px'>
                <img src={userProfilePicture(profilePicture)} className="rounded-circle" />
            </div>
            <p className="mb-0 fw-semibold">{name}</p>
            {provider === 'google' && <i className="fab fa-google"></i>}
        </div>
    )
}

export default NameCell