import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { PageTitle } from "../../../_metronic/layout/core"
import DeleteConfirmModal from "../../components/DeleteConfirmModal"
import { deleteSetting, getSettings } from "../../models/settings"
import Authorization from "../../routing/Authorization"
import SettingPagination from "./components/SettingPagination"
import SettingsTable from "./components/SettingTable/Table"
import { columnAliases } from "./components/SettingTable/_columns"

const WebsiteSettings = () => {
    const pageSize = 10
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({})
    const [showDeleteSettingModal, setShowDeleteSettingModal] = useState()
    const [deleteCourseTitle, setDeleteCourseTitle] = useState()

    const queryClient = useQueryClient()
    const { isLoading, isError, error, data, isSuccess } = useQuery(['settings'], () => getSettings(null, page, pageSize, sortBy))
    const { mutate: deleteSettingMutate, isLoading: isDeleteSettingLoading } = useMutation(settingId => deleteSetting(settingId), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries(['settings'])
        },
        onError: error => toast.error(error.message)
    })

    useEffect(() => {
        queryClient.fetchQuery(['settings'])
    }, [queryClient, filters, page, sortBy])


    return (
        <>
            <div className="min-h-500px">
                <PageTitle>Setting</PageTitle>
                <div className="card">
                    <div className="card-body p-6">
                        <div className="d-flex justify-content-between align-items-start mb-5">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    placeholder="Search setting"
                                />
                            </div>
                            <Link to='/settings/website/create' className="btn btn-sm btn-light-primary">New Setting</Link>
                        </div>
                        <SettingsTable
                            isLoading={isLoading}
                            isError={isError}
                            error={error}
                            data={!isLoading ? data.data : []}
                            currentPage={page}
                            pageSize={pageSize}
                            onSortByChange={sortBy => setSortBy(() => {
                                if (sortBy.length) return {
                                    id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                    direction: sortBy[0].desc ? 'DESC' : 'ASC'
                                }

                                return {}
                            })}
                            onDelete={(settingId, courseTitle) => {
                                setShowDeleteSettingModal(settingId)
                                setDeleteCourseTitle(courseTitle)
                            }}
                        />
                        {isSuccess && <SettingPagination
                            onPageChange={page => setPage(page)}
                            totalCount={data?.meta.total}
                            currentPage={page}
                            pageSize={pageSize}
                            from={data?.meta.from}
                            to={data?.meta.to}
                        />}
                    </div>
                </div>
            </div>

            <DeleteConfirmModal
                show={showDeleteSettingModal}
                handleClose={() => setShowDeleteSettingModal(false)}
                onConfirm={() => {
                    deleteSettingMutate(showDeleteSettingModal)
                    setShowDeleteSettingModal(false)
                }}
                isConfirmLoading={isDeleteSettingLoading}
                name={deleteCourseTitle}
            />
        </>
    )
}

export default Authorization(WebsiteSettings, ['admin'])