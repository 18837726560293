import { useQueryClient } from "react-query"
import "react-datepicker/dist/react-datepicker.css"

import { PageTitle } from "../../../../_metronic/layout/core"
import { getMerchants } from "../../../models/merchants"
import ProgressReport from "./components/ProgressReport"
import CompletionReport from "./components/CompletionReport"
import RedemptionReport from "./components/RedemptionReport"


const Prakerja = () => {
    const queryClient = useQueryClient()

    const searchMerchant = async search => queryClient.fetchQuery('merchants-digital-platform', () => getMerchants({ name: search, isDigitalPlatform: 1, rows: 100 })).then(res => res.data.map(merchant => ({
        label: merchant.name,
        value: merchant.id,
    })))

    return (
        <div className="min-h-500px">
            <PageTitle>Prakerja Reports</PageTitle>
            <div className="row">
                <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <ProgressReport
                        onSearchMerchant={searchMerchant}
                    />
                </div>
                <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <RedemptionReport
                        onSearchMerchant={searchMerchant}
                    />
                </div>
                <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <CompletionReport
                        onSearchMerchant={searchMerchant}
                    />
                </div>
            </div>
        </div>
    )
}

export default Prakerja