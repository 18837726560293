import DateTimeCell from "../../../../components/Table/cells/DateTime"
import ActionsCell from "./ActionsCell"
import NameCell from "./NameCell"

const userColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        Cell: ({ ...props }) => <strong className="text-muted">{((props.initialState.currentPage - 1) * props.initialState.pageSize) + props.row.index + 1}</strong>
    }, {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row, data }) => <NameCell provider={data[row.index].provider} name={data[row.index].name} profilePicture={data[row.index].profile_picture_url} />
    }, {
        Header: 'Email',
        accessor: 'email'
    }, {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: ({ row, data }) => <DateTimeCell dateTime={data[row.index].created_at} />
    }, {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ ...props }) => <ActionsCell user={props.data[props.row.index]} onLoginAsUser={props.onLoginAsUser} onResetPassword={props.onResetPassword} />
    }
]

const columnAliases = {
    number: 'id',
}

export { userColumns, columnAliases }