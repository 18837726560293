import { ProgressBar } from "react-bootstrap"

const QuotaCell = ({ participantCount, quota }) => {
    return (
        <>
            <div className="d-flex justify-content-between mb-1">
                <p className="fw-semibold mb-0 fs-8">{((participantCount / quota) * 100).toFixed(1)}%</p>
                <p className="fw-semibold mb-0 fs-8">{participantCount} / {quota}</p>
            </div>
            <ProgressBar variant="primary" now={participantCount} min={0} max={quota} />
        </>
    )
}

export default QuotaCell