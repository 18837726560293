import DateTimeCell from "../../../../../components/Table/cells/DateTime"
import NumberCell from "../../../../../components/Table/cells/Number"
import ActionsCell from "./ActionsCell"
import AllowedCoursesCell from "./AllowedCoursesCell"
import QuotaCell from "./QuotaCell"
import StatusCell from "./StatusCell"

const campaignColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        className: 'ps-2',
        Cell: ({ ...props }) => <NumberCell {...props} />
    }, {
        Header: 'Start Date',
        accessor: 'start_date',
        Cell: ({ ...props }) => <DateTimeCell dateTime={props.data[props.row.index].start_date} withTime={false} />
    }, {
        Header: 'End Date',
        accessor: 'end_date',
        Cell: ({ ...props }) => <DateTimeCell dateTime={props.data[props.row.index].end_date} withTime={false} />
    }, {
        Header: 'Allowed Courses',
        accessor: 'allowed_course_ids',
        disableSortBy: true,
        Cell: ({ ...props }) => <AllowedCoursesCell allowedCourseIds={props.data[props.row.index].allowed_course_ids} onClick={props.onAllowedCoursesClick} />
    }, {
        Header: 'Campaign Status',
        accessor: 'status',
        Cell: ({ ...props }) => <StatusCell status={props.data[props.row.index].status} />
    }, {
        Header: 'Form Status',
        accessor: 'form_status',
        Cell: ({ ...props }) => <StatusCell status={props.data[props.row.index].form_status} />
    }, {
        Header: 'Quota',
        accessor: 'quota',
        className: 'w-200px',
        Cell: ({ ...props }) => <QuotaCell participantCount={props.data[props.row.index].participant_count} quota={props.data[props.row.index].quota} />
    }, {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        className: 'text-end',
        Cell: ({ ...props }) => <ActionsCell campaign={props.data[props.row.index]} onEdit={props.onEdit} onDelete={props.onDelete} />
    }
]

const columnAliases = {
    number: 'id'
}

export { campaignColumns, columnAliases }