import CopyToClipboard from "react-copy-to-clipboard"
import { Helmet } from "react-helmet"
import { useQuery, useQueryClient } from "react-query"
import { useOutletContext, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { getMeetingParticipants, getNotJoinedStudents, getRealtimeActivity, getScheduleSessionParticipants, getUnverifiedStudents } from "../../../../../../../models/courses/schedules"
import PageSpinner from "../../../../../../../components/Spinner/PageSpinner"
import PageError from "../../../../../../../components/Error/PageError"
import { useState } from "react"
import FollowUpModal from "../../../Students/components/FollowUpModal/FollowUpModal"
import moment from "moment"


const UnverifiedStudents = ({ queryKey, scheduleId, sessionId, sessionStatus, onFollowUp }) => {
    const [shouldRefetch, setShouldRefetch] = useState(sessionStatus === 'active')

    const { isLoading, data, isError, error } = useQuery([queryKey], () => getUnverifiedStudents(scheduleId, sessionId), {
        refetchInterval: shouldRefetch ? 3000 : false,
        onSuccess: data => {
            if (data?.length < 1) setShouldRefetch(false)
        }
    })

    return (
        <div className="card">
            <div className="card-body p-6">
                <h6 className="mb-6">Not Verified {!isLoading && `(${data?.length})`}</h6>
                {isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : data?.length < 1 ? (
                    <PageError height={300}>
                        <div className="text-center">
                            <i className="fa fa-check-circle fs-1 text-success"></i>
                            <p className="mb-0 mt-1 text-success">All students are verified!</p>
                        </div>
                    </PageError>
                ) : (
                    <div className="d-flex flex-column h-300px overflow-auto">
                        {data.map((student, i) => (
                            <div key={i} className={"d-flex align-items-center justify-content-between py-3 " + (i > 0 ? 'border-top ' : '') + (i === data.length - 1 ? 'pb-0' : '')}>
                                <p className="mb-0 fw-semibold">{student.name}</p>
                                {student.phone_number && sessionStatus !== 'finished' && (
                                    <button type="button" className="btn btn-sm btn-light-success" onClick={() => onFollowUp(student)}>
                                        <i className="fab fa-whatsapp p-0 m-0 fs-4"></i>
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

const NotJoinedStudents = ({ queryKey, scheduleId, sessionId, onFollowUp }) => {
    const { isLoading, data, isError, error } = useQuery([queryKey], () => getNotJoinedStudents(scheduleId, sessionId), {
        refetchInterval: 3000
    })

    return (
        <div className="card">
            <div className="card-body p-6">
                <h6 className="mb-6">Verified but not joined {!isLoading && `(${data?.length})`}</h6>
                {isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : data?.length < 1 ? (
                    <PageError height={300}>
                        <p className="mb-0 text-muted">No Result</p>
                    </PageError>
                ) : (
                    <div className="d-flex flex-column h-300px overflow-auto">
                        {data.map((student, i) => (
                            <div key={i} className={"d-flex align-items-center justify-content-between py-3 " + (i > 0 ? 'border-top ' : '') + (i === data.length - 1 ? 'pb-0' : '')}>
                                <p className="mb-0 fw-semibold">{student.name}</p>
                                {student.phone_number && (
                                    <div className="flex-shrink-0">
                                        {student.meeting_url && (
                                            <CopyToClipboard text={`${student.name} - ${student.meeting_url}`} onCopy={() => toast.success('Personal meeting URL copied!')}>
                                                <button type="button" className="btn btn-sm btn-light-primary me-2"><i className="fa fa-link p-0 m-0"></i></button>
                                            </CopyToClipboard>
                                        )}
                                        <button type="button" className="btn btn-sm btn-light-success" onClick={() => onFollowUp(student)}><i className="fab fa-whatsapp p-0 m-0 fs-4"></i></button>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

const NotPresentStudents = ({ queryKey, scheduleId, sessionId }) => {
    const { isLoading, data, isError, error } = useQuery([queryKey], () => getScheduleSessionParticipants({
        scheduleId,
        sessionId,
        status: 'not_present',
        verified_status: 'verified',
        rows: 100
    }))

    return (
        <div className="card">
            <div className="card-body p-6">
                <h6 className="mb-6">Not Present {!isLoading && `(${data.data?.length})`}</h6>
                {isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : data.data?.length < 1 ? (
                    <PageError height={300}>
                        <p className="mb-0 text-muted">No Result</p>
                    </PageError>
                ) : (
                    <div className="d-flex flex-column h-300px overflow-auto">
                        {data.data.map((student, i) => (
                            <div key={i} className={"d-flex align-items-center justify-content-between py-3 " + (i > 0 ? 'border-top ' : '') + (i === data.length - 1 ? 'pb-0' : '')}>
                                <p className="mb-0 fw-semibold">{student.name}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

const PresentStudents = ({ queryKey, scheduleId, sessionId }) => {
    const { isLoading, data, isError, error } = useQuery([queryKey], () => getScheduleSessionParticipants({
        scheduleId,
        sessionId,
        status: 'present',
        verified_status: 'verified',
        rows: 100
    }))

    return (
        <div className="card">
            <div className="card-body p-6">
                <h6 className="mb-6">Present {!isLoading && `(${data.data?.length})`}</h6>
                {isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : data.data?.length < 1 ? (
                    <PageError height={300}>
                        <p className="mb-0 text-muted">No Result</p>
                    </PageError>
                ) : (
                    <div className="d-flex flex-column h-300px overflow-auto">
                        {data.data.map((student, i) => (
                            <div key={i} className={"d-flex align-items-center justify-content-between py-3 " + (i > 0 ? 'border-top ' : '') + (i === data.length - 1 ? 'pb-0' : '')}>
                                <p className="mb-0 fw-semibold">{student.name}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

const MeetingParticipants = ({ queryKey, scheduleId, sessionId, onFollowUp }) => {
    const { isLoading, data, isError, error } = useQuery([queryKey], () => getMeetingParticipants(scheduleId, sessionId), {
        refetchInterval: 3000
    })

    return (
        <div className="card">
            <div className="card-body p-6">
                <h6 className="mb-6">Joined {!isLoading && `(${data?.length})`}</h6>
                {isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : data?.length < 1 ? (
                    <PageError height={300}>
                        <p className="mb-0 text-muted">No Result</p>
                    </PageError>
                ) : (
                    <div className="d-flex flex-column h-300px overflow-auto">
                        {data.map((student, i) => (
                            <div key={i} className={"d-flex align-items-center justify-content-between py-3 " + (i > 0 ? 'border-top ' : '') + (i === data.length - 1 ? 'pb-0' : '')}>
                                <p className="mb-0 fw-semibold">{student.name}</p>
                                {student.phone_number && (
                                    <div className="flex-shrink-0">
                                        {student.meeting_url && (
                                            <CopyToClipboard text={`${student.name} - ${student.meeting_url}`} onCopy={() => toast.success('Personal meeting URL copied!')}>
                                                <button type="button" className="btn btn-sm btn-light-primary me-2"><i className="fa fa-link p-0 m-0"></i></button>
                                            </CopyToClipboard>
                                        )}
                                        <button type="button" className="btn btn-sm btn-light-success" onClick={() => onFollowUp(student)}><i className="fab fa-whatsapp p-0 m-0 fs-4"></i></button>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

const RealtimeActivity = ({ queryKey, scheduleId, sessionId }) => {
    const { isLoading, data, isError, error } = useQuery([queryKey], () => getRealtimeActivity(scheduleId, sessionId), {
        refetchInterval: 3000
    })

    return (
        <div className="card">
            <div className="card-body p-6">
                <h6 className="mb-6">Realtime Activity</h6>
                {isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : data?.length < 1 ? (
                    <PageError height={300}>
                        <p className="mb-0 text-muted">No Result</p>
                    </PageError>
                ) : (
                    <div className="h-300px overflow-auto">
                        <div class="timeline-label">
                            {data.map((log, i) => (
                                <div class="timeline-item" key={i}>
                                    <div class="timeline-label fw-bold text-gray-800 fs-6">{moment.utc(log.created_at).local().format('HH:mm')}</div>
                                    <div class="timeline-badge"><i class={"fa fa-genderless fs-1 " + (log.type === 'meeting.meeting.participant_joined_waiting_room' ? 'text-warning' : log.type === 'meeting.participant_joined' ? 'text-success' : 'text-danger')}></i></div>
                                    <div class="timeline-content text-muted ps-3"><span className="fw-bold text-black">{log.display_name}</span> {log.type === 'meeting.participant_joined_waiting_room' ? 'in waiting room' : log.type === 'meeting.participant_joined' ? 'joined the meeting' : 'leave the meeting'}.</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default function Overview() {
    const { scheduleId, sessionId } = useParams()
    const { schedule, session } = useOutletContext()
    
    const [showFollowUpModal, setShowFollowUpModal] = useState(false)
    const [followUpData, setFollowUpData] = useState(null)

    const queryClient = useQueryClient()
    
    const queryKeys = {
        unverifiedStudents: `unverified-students-${sessionId}`,
        notJoinedStudents: `not-joined-students-${sessionId}`,
        meetingParticipants: `meeting-participants-${sessionId}`,
        notPresentStudents: `not-present-students-${sessionId}`,
        presentStudents: `present-students-${sessionId}`,
        realtimeActivity: `realtime-activity-${sessionId}`
    }

    return (
        <>
            <Helmet>
                <title>{`Overview - ${session?.lesson?.title}`}</title>
            </Helmet>
            <div className="card">
                <div className="card-body p-6">
                    <div className="d-flex justify-content-between align-items-center gap-3 mb-3">
                        <p className="mb-0 fw-bold">Host</p>
                        <p className="mb-0 fw-semibold">{schedule?.zoom_user?.email || '-'}</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center gap-3">
                        <p className="mb-0 fw-bold flex-shrink-0">Meeting URL</p>
                        <div className="d-flex align-items-center gap-3">
                            <a href={session.meeting_url} target="_blank" rel="noreferrer">{session.meeting_url}</a>
                            <CopyToClipboard text={session.meeting_url} onCopy={() => toast.success('Link copied')}>
                                <button type="button" className="btn btn-sm btn-light-primary">Copy</button>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-6">
                <div className="col-12 col-md-4">
                    <UnverifiedStudents
                        queryKey={queryKeys.unverifiedStudents}
                        scheduleId={scheduleId} 
                        sessionId={sessionId}
                        sessionStatus={session.status}
                        onFollowUp={data => {
                            setFollowUpData(data)
                            setShowFollowUpModal(true)
                        }}
                    />
                </div>
                <div className="col-12 col-md-4">
                    {session.status === 'active' ? (
                        <NotJoinedStudents
                            queryKey={queryKeys.notJoinedStudents}
                            scheduleId={scheduleId} 
                            sessionId={sessionId}
                            onFollowUp={data => {
                                setFollowUpData(data)
                                setShowFollowUpModal(true)
                            }}
                        />
                    ) : session.status === 'finished' ? (
                        <NotPresentStudents
                            queryKey={queryKeys.notPresentStudents}
                            scheduleId={scheduleId} 
                            sessionId={sessionId}
                        />
                    ) : null}
                </div>
                <div className="col-12 col-md-4">
                    {session.status === 'active' ? (
                        <MeetingParticipants
                            queryKey={queryKeys.meetingParticipants}
                            scheduleId={scheduleId} 
                            sessionId={sessionId}
                            sessionStatus={session.status}
                            onFollowUp={data => {
                                setFollowUpData(data)
                                setShowFollowUpModal(true)
                            }}
                        />
                    ) : session.status === 'finished' ? (
                        <PresentStudents
                            queryKey={queryKeys.presentStudents}
                            scheduleId={scheduleId} 
                            sessionId={sessionId}
                        />
                    ) : null}
                </div>
                {session.status === 'active' && (
                    <div className="col-12 col-md-8 mt-6">
                        <RealtimeActivity
                            queryKey={queryKeys.realtimeActivity}
                            scheduleId={scheduleId}
                            sessionId={sessionId}
                        />
                    </div>
                )}
            </div>

            <FollowUpModal
                queryClient={queryClient}
                show={showFollowUpModal}
                onHide={() => {
                    setShowFollowUpModal(false)
                    setFollowUpData(null)
                }}
                onSetShow={show => setShowFollowUpModal(show)}
                scheduleId={schedule.id}
                recipient={followUpData}
            />
        </>
    )
}