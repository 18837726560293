import { useFormik } from "formik"
import Collapse from "react-bootstrap/Collapse"


const ApplicantFilter = ({ show, onSubmit }) => {
    const formik = useFormik({
        initialValues: {
            full_name: '',
            gender: '',
            phone_no: '',
            digital_platforms: '',
            status: 'pending',
        },
        onSubmit
    })

    return (
        <Collapse in={show}>
            <div className="border rounded mt-6">
                <form className="row p-6" onSubmit={formik.handleSubmit}>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label">
                                <small>Full Name</small>
                            </label>
                            <input type="text" name="full_name" className="form-control form-control-sm" onChange={formik.handleChange} value={formik.values.full_name} />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label"><small>Digital Platform</small></label>
                            <select name="digital_platforms" className="form-select form-select-sm" onChange={formik.handleChange} value={formik.values.digital_platforms}>
                                <option value="">All</option>
                                <option value="bukalapak">Bukalapak</option>
                                <option value="karier.mu">Karier.mu</option>
                                <option value="pijar_mahir">Pijar Mahir</option>
                                <option value="tokopedia">Tokopedia</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label">
                                <small>Phone No</small>
                            </label>
                            <input type="text" name="phone_no" className="form-control form-control-sm" onChange={formik.handleChange} value={formik.values.phone_no} />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-label"><small>Status</small></label>
                            <select name="status" className="form-select form-select-sm" onChange={formik.handleChange} value={formik.values.status}>
                                <option value="">All</option>
                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                                <option value="resubmit">Resubmit</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label">
                                <small>Gender</small>
                            </label>
                            <select name="gender" className="form-select form-select-sm" onChange={formik.handleChange} value={formik.values.gender}>
                                <option value="">All</option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 text-end mt-6">
                        {formik.dirty && (
                            <button type="button" className="btn btn-sm btn-outline btn-outline-danger me-3" onClick={() => {
                                formik.resetForm()
                                onSubmit({})
                            }}>Clear</button>
                        )}
                        <button type="submit" className="btn btn-sm btn-primary">Apply</button>
                    </div>
                </form>
            </div>
        </Collapse>
    )
}

export default ApplicantFilter