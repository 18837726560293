import { Modal } from "react-bootstrap"
import { useQuery } from "react-query"
import moment from "moment"

import { getZoomLogs } from "../../../../../../../../models/courses/schedules"
import PageSpinner from "../../../../../../../../components/Spinner/PageSpinner"
import PageError from "../../../../../../../../components/Error/PageError"

const ZoomLogsModal = ({ show, onHide, scheduleId, sessionId, registrantId = null }) => {
    const { isLoading, isError, error, data } = useQuery(['zoom-logs'], () => getZoomLogs(scheduleId, sessionId, registrantId), {
        enabled: !!show
    })
    
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Zoom Logs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {show && (
                    <>
                        {isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : (
                            <div class="timeline-label">
                                {data.map((log, i) => (
                                    <div class="timeline-item" key={i}>
                                        <div class="timeline-label fw-bold text-gray-800 fs-6">{moment.utc(log.datetime).local().format('HH:mm')}</div>
                                        <div class="timeline-badge"><i class={"fa fa-genderless fs-1 " + (log.type === 'in_waiting_room' ? 'text-warning' : log.type === 'in_meeting' ? 'text-success' : 'text-danger')}></i></div>
                                        <div class="timeline-content text-muted ps-3"><span className="fw-bold text-black">{log.name}</span> {log.type === 'in_waiting_room' ? 'in waiting room' : log.type === 'in_meeting' ? 'joined the meeting' : 'leave the meeting'}.</div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default ZoomLogsModal