import { Modal } from "react-bootstrap"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { updateCampaign } from "../../../../models/tunjangan-hari-karya"
import CampaignForm from "./CampaignForm"


const EditCampaignModal = ({ queryClient, queryKey, campaign, show, onHide }) => {
    const { mutate, isLoading } = useMutation(data => updateCampaign(campaign.id, data), {
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey])
            toast.success('Campaign updated')
            onHide()
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Campaign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CampaignForm
                    campaign={campaign}
                    onSubmit={data => mutate(data)}
                    isSubmitLoading={isLoading}
                />
            </Modal.Body>
        </Modal>
    )
}

export default EditCampaignModal